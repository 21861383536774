import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "../../redux/hooks";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import {
  LanguageActionTypes,
  changeLanguageActionCreator,
} from "../../redux/language/languageActions";
import { useTranslation } from "react-i18next";
import { withTranslation, WithTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import { MessengerChat } from "react-messenger-chat-plugin";
import { LoginSocialGoogle } from "reactjs-social-login";
import { GoogleLoginButton } from "react-social-login-buttons";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import {
  Link,
  DirectLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import {
  Button,
  Descriptions,
  Row,
  Col,
  Select,
  Space,
  Steps,
  Table,
  Tag,
  Typography,
  AutoComplete,
  Form,
  Input,
  InputNumber,
  Checkbox,
  notification,
  Modal,
  Collapse,
  Carousel,
  Dropdown,
  Menu,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  WarningFilled,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import ReactECharts from "echarts-for-react";
import { useGoogleStoreSelector, useGoogleStoreAction } from "./GoogleStore";
import GoogleAuthButton from "./GoogleAuthButton";
import ScoreChart from "./ScoreChart";
import Device from "./Device";
import utilsIndex from "../../utils/index";
import docCookies from "../../utils/docCookies";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./CheckAssistant.scss";

import checkListInit from "./CheckListInit";

import imgLogo from "../../images/checkAssistant/logo.png";
import imgIconMain from "../../images/checkAssistant/icon-main.png";
import imgIconBeta from "../../images/checkAssistant/icon-beta.png";
import imgStep from "../../images/checkAssistant/step.png";
import imgLoginAuth from "../../images/checkAssistant/login-auth.png";
import imgContact from "../../images/checkAssistant/contact.png";
import imgFooter from "../../images/checkAssistant/footer.webp";
import imgGMPC from "../../images/checkAssistant/GMPC.png";

import laurelLeft from "../../images/checkAssistant/laurel_left.png";
import laurelReft from "../../images/checkAssistant/laurel_right.png";
import iconOnModal from "../../images/checkAssistant/icon_on_modal_1.png";
import timesCheck from "../../images/checkAssistant/times_check.png";

import imgWarn from "../../images/checkAssistant/icon_warn.png";
import imgWarn1 from "../../images/checkAssistant/icon_warn1.png";
import imgService01 from "../../images/checkAssistant/service/01.webp";
import imgService01PC from "../../images/checkAssistant/service/01-pc.png";
import imgService03Plan01 from "../../images/checkAssistant/service/plan1.webp";
import imgService03Plan02 from "../../images/checkAssistant/service/plan2.webp";
import imgService03Plan03 from "../../images/checkAssistant/service/plan3.webp";
import imgService03Plan04 from "../../images/checkAssistant/service/plan4.webp";
import imgStep01 from "../../images/checkAssistant/step1.png";
import imgStep02 from "../../images/checkAssistant/step2.png";
import imgStep03 from "../../images/checkAssistant/step3.png";
import imgIconArrow from "../../images/checkAssistant/icon-arrow.png";
import imgIconFix from "../../images/checkAssistant/icon-fix.png";

// 尚未檢核
import statusNotYet from "../../images/checkAssistant/status/not-yet.png";
// 正常運作
import statusNormal from "../../images/checkAssistant/status/normal.png";
// 建議修正
import statusFix from "../../images/checkAssistant/status/fix.png";
// 數據異常
import statusError from "../../images/checkAssistant/status/error.png";
import utils from "../../utils/index";

const CheckboxGroup = Checkbox.Group;

const { Panel } = Collapse;

const { Step } = Steps;

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

const formLayout = {
  labelCol: {
    // span: 7,
    span: 3,
  },
  wrapperCol: {
    // span: 10,
    span: 24,
  },
};
let submitBtnOffset = 3;
// const { Step } = Steps

const validateMessages = {
  required: "${label} 是必填項目",
  types: {
    email: "信箱格式必須正確",
    // email: "${label} is not a valid email!",
    // number: "${label} is not a valid number!",
  },
  // number: {
  //   range: "${label} must be between ${min} and ${max}",
  // },
};

function onChange(value) {
  console.log(`selected ${value}`);
}

function onSearch(val) {
  console.log("search:", val);
}

const REDIRECT_URI = "http://localhost:3000/account/login";

const CheckAssistant = () => {
  const device = Device();
  const language = useSelector((state) => state.language);
  const languageList = useSelector((state) => state.languageList);
  const dispatch = useDispatch();
  const [collapseKeys, setCollapseKeys] = useState([]);
  // const prevKeysRef = useRef([]);
  const previousKeysRef = useRef([]);

  // useEffect(() => {
  //   if (collapseKeys.length > 0) {
  //     console.log("Items opened: ", collapseKeys);
  //   }
  // }, [collapseKeys]);

  const { t } = useTranslation();

  const setCookie = (pName, pValue, pTime, pType) => {
    // pValue = encodeURIComponent(pValue);  // 避免中文在 safari 會錯誤
    var d = new Date();
    var pTime = pTime !== undefined ? pTime : 30; // 預設 30 天
    if (pType == "hour") {
      // 依『小時』設定
      d.setTime(d.getTime() + pTime * 60 * 60 * 1000);
    } else {
      // 依『天』設定
      if (pTime === 0) {
        d.setHours(23, 59, 59, 999); // 記錄到當天23點59分59秒
      } else {
        d.setTime(d.getTime() + pTime * 24 * 60 * 60 * 1000);
      }
    }
    var expires = "expires=" + d.toUTCString();
    var path = "path=/";
    document.cookie = pName + "=" + pValue + "; " + expires + "; " + path;
  };

  const getCookie = (pName) => {
    var name = pName + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim();
      if (c.indexOf(name) == 0)
        return decodeURIComponent(c.substring(name.length, c.length));
    }
    return "";
  };
  const languageNavigator = window.navigator.language;
  const languageCookie = getCookie("td-language")
    ? getCookie("td-language")
    : "tw";
  const outOfQuotaCookie = getCookie("td-out_of_quota");

  useEffect(() => {
    let languageCurrect = "";
    if (languageCookie) {
      languageCurrect = languageCookie;
    } else {
      if (languageNavigator == "zh-TW") {
        languageCurrect = "tw";
      } else {
        languageCurrect = "cn";
      }
    }
    dispatch(changeLanguageActionCreator(languageCurrect));

    setOutOfQuota(outOfQuotaCookie);
  }, []);

  const menuClickHandler = (e) => {
    console.log(e);
    const key = e.key;
    dispatch(changeLanguageActionCreator(key));
    setCookie("td-language", key);
  };

  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState();

  const handleMenuClick = (e) => {
    console.log("click", e);
  };

  const items = languageList.map((l) => {
    return {
      label: l.name,
      key: l.code,
    };
  });

  const menuProps = {
    items,
    onClick: menuClickHandler,
  };

  const onLoginStart = useCallback(() => {
    alert("login start");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  const openNotification = () => {
    notification.info({
      message: "表單資料傳輸中",
      description: "請稍待片刻",
      // onClick: () => {
      //   console.log("Notification Clicked!")
      // },
    });
  };

  const {
    google_gapi_loaded,
    google_gapi_loaded_error,
    google_gapi_analyticsadmin_loaded,
    google_gapi_analyticsadmin_loaded_error,
    google_gapi_analyticsdata_loaded,
    google_gapi_analyticsdata_loaded_error,

    google_is_signed_in,
    google_user_email,
  } = useGoogleStoreSelector((state) => state);

  /* api */
  const [checkListSummaries, setCheckListSummaries] = useState({
    main: {
      TimeStamp: "",
      PropertyID: "",
      CrossDomain: false,
      IndustryCategory: "",
      TimeZone: "",
      Currency: "",
      GoogleSignals: "",
      DataRetention: "",
      LoginEmail: "",
      Score: 0,
      EngagementRate: 0,
      DataStreams: [],
      Domains: [],
      UserProperties: [],
      GoogleAdsLinking: [],
      NondefConvEvents: [],
      Audiences: [],
      PredictiveAudiences: [],
      Eccomerce: [],
      Sessions: [],
      Contact: false,
      Contact_Name: "",
      Contact_Phone: "",
      Contact_Email: "",
      Contact_Others: "",
      Contact_Items: [],
      Contact_Time: [],
    },
  });
  const [isCheckApiFetchFinished, setIsCheckApiFetchFinished] = useState(false);
  const [isSubmitContactForm, setIsSubmitContactForm] = useState(false);
  const [apiId, setApiId] = useState(null);
  const [collapseActiveKey, setCollapseActiveKey] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isStarModalVisible, setIsStarModalVisible] = useState(true);
  const [isTimesWaringModalVisible, setIsTimesWaringModalVisible] =
    useState(false);
  const [outOfQuota, setOutOfQuota] = useState(false);

  const [accountSummaries, setAccountSummaries] = useState([]);
  const [accountSummariesLoading, setAccountSummariesLoading] = useState(false);
  const [accountSummariesLoaded, setAccountSummariesLoaded] = useState(false);
  const [account, setAccount] = useState("");

  // 總分
  const [score, setScore] = useState(0);
  // 總分
  const [prScore, setPrScore] = useState(0);

  // 1. 追蹤代碼編號 Property ID
  const [propertySummaries, setPropertySummaries] = useState([]);
  const [property, setProperty] = useState("");

  // 2. 資料串流數量 Data Streams
  const [dataStreams, setDataStreams] = useState(null);
  const [webDataStreams, setWebDataStreams] = useState(null);
  const [androidAppDataStreams, setAndroidAppDataStreams] = useState(null);
  const [iosAppDataStreams, setIosAppDataStreams] = useState(null);

  // 3. 追蹤網域數量 Domains
  const [hostName, setHostName] = useState(null);

  // 4. 是否有跨網域追蹤 Cross Domain Measurement
  const [crossDomain, setCrossDomain] = useState(null);

  // 5. 產業類別設定 Industry Category
  const [industryCategory, setIndustryCategory] = useState(null);

  // 6. 時區設定 Time Zone
  const [timeZone, setTimeZone] = useState(null);

  // 7. 貨幣設定 Currency
  const [currencyCode, setCurrencyCode] = useState(null);

  // 8. 啟用 Google 信號 Google Signals
  const [googleSignalsSettings, setGoogleSignalsSettings] = useState(null);

  // 9. 資料保存期限 Data Retention
  const [ga360, setGa360] = useState(null);
  const [dataRetentionSettings, setDataRetentionSettings] = useState(null);

  // 10. 定義使用者屬性 User Properties
  const [userProperties, setUserProperties] = useState(null);

  // 11. 串接 Google Ads Google Ads Linking
  const [googleAdsLinks, setGoogleAdsLinks] = useState(null);

  // 12. 客戶有效互動率 Engagement Rate
  const [interactionRate, setInteractionRate] = useState(null);

  // 13. 非預設轉換事件 Conversion Events (Non-default)
  const [conversionEvents, setConversionEvents] = useState(null);

  // 14. 定義目標對象 Audiences
  const [audience, setAudience] = useState(null);

  // 15. 廣告預測功能 Predictive Audiences
  const [predicted, setPredicted] = useState(null);

  // 16. 電子商務代碼 E-Commerce
  const [eccomerce, setEccomerce] = useState(null);

  // 17. 工作階段比例 Sessions
  const [medium, setMedium] = useState(null);

  // 18. User-ID
  const [userId, setUserId] = useState(null);

  // 19. Data Streams Measurement Protocol
  const [
    webDataStreamsMeasurementProtocol,
    setWebDataStreamsMeasurementProtocol,
  ] = useState(null);
  const [
    androidAppDataStreamsMeasurementProtocol,
    setAndroidAppDataStreamsMeasurementProtocol,
  ] = useState(null);
  const [
    iosAppDataStreamsMeasurementProtocol,
    setIosAppDataStreamsMeasurementProtocol,
  ] = useState(null);

  // 20. google CPC trans
  const [googleCPCTrans, setGoogleCPCTrans] = useState(null);

  // 21. Unassigned
  const [unassigned, setUnassigned] = useState(null);

  const [googleApiFalse, setGoogleApiFalse] = useState(false);

  // 22. 預設轉換事件 Conversion Events (default)
  const [conversionEventsDefault, setConversionEventsDefault] = useState(null);
  /* api end */

  const handleFixQuation = () => {
    utilsIndex.sendGAEvent("fix_problem");
  };

  const [echartsOption, setEchartsOption] = useState({});

  const getEchartsOption = (score) => {
    // console.log("score = ", score)
    // const color = "#80B900" //进度颜色
    let color = "";
    // const score = "0" // 进度数据
    if (score < 50) {
      // 數據異常
      color = "#f90000";
    } else if ((score >= 50) & (score < 90)) {
      // 建議修正
      color = "#Fdb900";
    } else {
      // 正常運作
      color = "#7fb900";
    }

    if (score < 0) {
      score = 0;
    }

    return {
      tooltip: {
        show: false, // 去除鼠标hover提示框
      },
      title: {
        text: score,
        x: "center",
        y: "center",
        textStyle: {
          color: "#5f6988",
          fontSize: 24,
        },
      },
      series: [
        {
          type: "pie",
          name: "名稱",
          radius: ["80%", "100%"], // 环的宽度
          center: ["50%", "50%"],
          hoverAnimation: false, // 鼠标hover高亮隐藏
          label: {
            normal: {
              position: "inner",
              show: false,
            },
          },
          data: [
            {
              value: score,
              itemStyle: {
                normal: {
                  color: color,
                },
                label: {
                  show: false, // 去掉指示线
                },
                labelLine: {
                  show: false, // 去掉指示线
                },
              },
            },
            {
              value: 100 - score,
              itemStyle: {
                normal: {
                  color: "#d3daf5",
                },
                label: {
                  show: false, // 去掉指示线
                },
                labelLine: {
                  show: false, // 去掉指示线
                },
              },
            },
          ],
        },
      ],
    };
  };

  const callback = (key) => {
    // console.log(key)
    const newKey = key.filter(function (item) {
      // 第 0 個點了不要顯示
      return item != "0";
    });
    // console.log(newKey)
    setCollapseActiveKey(newKey);
  };

  /* api */
  const checkDataStreams = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties.dataStreams
        .list({
          parent: property,
        })
        .then(
          function (response) {
            console.log(response);
            if (response.result.dataStreams) {
              let data = response.result.dataStreams.map((item) => {
                switch (item.type) {
                  case "WEB_DATA_STREAM": {
                    return (
                      "Web | " +
                      (item.displayName || item.name) +
                      " | " +
                      item.webStreamData.measurementId
                    );
                  }
                  case "ANDROID_APP_DATA_STREAM": {
                    return (
                      "Android | " +
                      (item.displayName || item.name) +
                      " | " +
                      item.androidAppStreamData.firebaseAppId
                    );
                  }
                  case "IOS_APP_DATA_STREAM": {
                    return (
                      "Ios | " +
                      (item.displayName || item.name) +
                      " | " +
                      item.iosAppStreamData.firebaseAppId
                    );
                  }
                  default: {
                    return (
                      "Unspecified | " +
                      (item.displayName || item.name) +
                      " | " +
                      item.measurementId
                    );
                  }
                }
              });
              console.log("2. dataStreams", data);
              setDataStreams(data);
              resolve(data);
            } else {
              console.log("2. dataStreams", []);
              setDataStreams([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("2. dataStreams", err);
            setDataStreams([]);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkWebDataStreams = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties.dataStreams
        .list({
          parent: property,
        })
        .then(
          function (response) {
            if (response.result.webDataStreams) {
              let data = response.result.webDataStreams.map(
                (item) =>
                  "Web | " + item.displayName + " | " + item.measurementId
              );
              console.log("2. webDataStreams", data);
              setWebDataStreams(data);
              resolve(data);
            } else {
              console.log("2. webDataStreams", []);
              setWebDataStreams([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("2. webDataStreams", err);
            setWebDataStreams([]);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkAndroidAppDataStreams = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties.androidAppDataStreams
        .list({
          parent: property,
        })
        .then(
          function (response) {
            if (response.result.androidAppDataStreams) {
              let data = response.result.androidAppDataStreams.map(
                (item) =>
                  "Android | " + item.displayName + " | " + item.firebaseAppId
              );
              console.log("2. androidAppDataStreams", data);
              setAndroidAppDataStreams(data);
              resolve(data);
            } else {
              console.log("2. androidAppDataStreams", []);
              setAndroidAppDataStreams([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("2. androidAppDataStreams error", err);
            setAndroidAppDataStreams([]);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkIosAppDataStreams = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties.iosAppDataStreams
        .list({
          parent: property,
        })
        .then(
          function (response) {
            if (response.result.iosAppDataStreams) {
              let data = response.result.iosAppDataStreams.map(
                (item) =>
                  "Ios | " + item.displayName + " | " + item.firebaseAppId
              );
              console.log("2. iosAppDataStreams", data);
              setIosAppDataStreams(data);
              resolve(data);
            } else {
              console.log("2. iosAppDataStreams", []);
              setIosAppDataStreams([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("2. iosAppDataStreams error", err);
            setIosAppDataStreams([]);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkHostName = () => {
    return new Promise((resolve, reject) => {
      gapi.client.analyticsdata.properties
        .runReport({
          property: property,
          resource: {
            dimensions: [
              {
                name: "hostName",
              },
            ],
            dateRanges: [
              {
                startDate: "30daysAgo",
                endDate: "yesterday",
              },
            ],
          },
        })
        .then(
          function (response) {
            if (response.result.rows) {
              let data = response.result.rows.map(
                (item) => item.dimensionValues[0].value
              );
              data = data.filter((item) => !item.includes("not set"));
              console.log("3. checkHostName", data);
              setHostName(data);
              resolve(data);
            } else {
              console.log("3. checkHostName", []);
              setHostName([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("checkHostName error", err);
            setHostName([]);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkCrossDomain = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsdata.properties
        .runReport({
          property: property,
          resource: {
            dimensions: [
              {
                name: "pageReferrer",
              },
            ],
            dateRanges: [
              {
                startDate: "90daysAgo",
                endDate: "yesterday",
              },
            ],
            dimensionFilter: {
              filter: {
                fieldName: "pageReferrer",
                stringFilter: {
                  matchType: "CONTAINS",
                  value: "_gl=",
                },
              },
            },
          },
        })
        .then(
          function (response) {
            if (response.result.rows) {
              let data = true;
              console.log("4. checkCrossDomain", data);
              setCrossDomain(data);
              resolve(data);
            } else {
              console.log("4. checkCrossDomain", false);
              setCrossDomain(false);
              resolve(false);
            }
          },
          function (err) {
            console.error("checkCrossDomain error", err);
            setCrossDomain(false);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkProperty = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties
        .get({
          name: property,
        })
        .then(
          function (response) {
            if (response.result) {
              console.log(
                "5. industryCategory",
                response.result.industryCategory || ""
              );
              setIndustryCategory(response.result.industryCategory || "");
              console.log(
                "7. currencyCode",
                response.result.currencyCode || ""
              );
              setCurrencyCode(response.result.currencyCode || "");
              console.log("6. timeZone", response.result.timeZone || "");
              setTimeZone(response.result.timeZone || "");
              setGa360(
                response.result.serviceLevel.includes("360") ? true : false
              );
              resolve(response.result);
            }
          },
          function (err) {
            console.error("checkProperty error", err);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkGoogleSignalsSettings = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties
        .getGoogleSignalsSettings({ name: property + "/googleSignalsSettings" })
        .then(
          function (response) {
            if (response.result) {
              console.log(
                "8. checkGoogleSignalsSettings",
                response.result.state || ""
              );
              setGoogleSignalsSettings(response.result.state || "");
              resolve(response.result.state || "");
            } else {
              console.log("8. checkGoogleSignalsSettings", "");
              setGoogleSignalsSettings("");
              resolve("");
            }
          },
          function (err) {
            console.error("checkGoogleSignalsSettings error", err);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkDataRetentionSettings = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties
        .getDataRetentionSettings({ name: property + "/dataRetentionSettings" })
        .then(
          function (response) {
            if (response.result) {
              console.log(
                "9. checkDataRetentionSettings",
                response.result.eventDataRetention || ""
              );
              setDataRetentionSettings(
                response.result.eventDataRetention || ""
              );
              resolve(response.result.eventDataRetention || "");
            }
          },
          function (err) {
            console.error("checkDataRetentionSettings error", err);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkUserId = () => {
    return new Promise((resolve, reject) => {
      gapi.client.analyticsdata.properties
        .runReport({
          property: property,
          resource: {
            dimensions: [
              {
                name: "signedInWithUserId",
              },
            ],
            dateRanges: [
              {
                startDate: "30daysAgo",
                endDate: "yesterday",
              },
            ],
          },
        })
        .then(
          function (response) {
            if (response.result.rows) {
              let data = response.result.rows.some(
                (item) => item.dimensionValues[0].value === "yes"
              );
              console.log("18. checkUserId", data);
              setUserId(data);
              resolve(data);
            } else {
              console.log("18. checkUserId", false);
              setUserId(false);
              resolve(false);
            }
          },
          function (err) {
            console.error("18. checkUserId error", err);
            setUserId(false);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkUserProperties = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsdata.properties
        .getMetadata({ name: property + "/metadata" })
        .then(
          function (response) {
            if (response.result) {
              let data = response.result.dimensions
                .filter((item) => item.apiName.includes("customUser"))
                .map((item) => item.apiName);
              console.log("10. checkUserProperties", data);

              setUserProperties(data);

              resolve(data);
            }
          },
          function (err) {
            console.error("checkUserProperties error", err);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkGoogleAds = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties.googleAdsLinks
        .list({ parent: property })
        .then(
          function (response) {
            if (response.result.googleAdsLinks) {
              let data = response.result.googleAdsLinks.map(
                (item) => item.customerId
              );
              console.log("11. checkGoogleAds", data);
              setGoogleAdsLinks(data);
              resolve(data);
            } else {
              console.log("11. checkGoogleAds", []);
              setGoogleAdsLinks([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("checkGoogleAds error", err);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkInteractionRate = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsdata.properties
        .runReport({
          property: property,
          resource: {
            metrics: [
              {
                name: "engagedSessions",
              },
              {
                name: "sessions",
              },
            ],
            dateRanges: [
              {
                startDate: "30daysAgo",
                endDate: "yesterday",
              },
            ],
          },
        })
        .then(
          function (response) {
            if (response.result.rows) {
              let data = (
                (Number(response.result.rows[0].metricValues[0].value) /
                  Number(response.result.rows[0].metricValues[1].value)) *
                100
              ).toFixed(2);
              console.log("12. checkInteractionRate", data);
              setInteractionRate(data);
              resolve(data);
            } else {
              console.log("12. checkInteractionRate", 0);
              setInteractionRate(0);
              resolve(0);
            }
          },
          function (err) {
            console.error("checkInteractionRate error", err);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkConversionEvents = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties.conversionEvents
        .list({
          parent: property,
        })
        .then(
          function (response) {
            if (response.result.conversionEvents) {
              let data = response.result.conversionEvents
                .filter((item) => item.custom)
                .map((item) => item.eventName);
              console.log("13. checkConversionEvents", data);
              setConversionEvents(data);
              resolve(data);
            } else {
              console.log("13. checkConversionEvents", []);
              setConversionEvents([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("checkConversionEvents error", err);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkAudience = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsdata.properties
        .runReport({
          property: property,
          resource: {
            metrics: [
              {
                name: "totalUsers",
              },
            ],
            dimensions: [
              {
                name: "audienceName",
              },
            ],
            dateRanges: [
              {
                startDate: "30daysAgo",
                endDate: "yesterday",
              },
            ],
          },
        })
        .then(
          function (response) {
            if (response.result.rows) {
              let data_audience = response.result.rows
                .filter(
                  (item) =>
                    !item.dimensionValues[0].value.match(
                      /(All Users)|(Purchasers)|(未來 7 天)|(接下來 28 天)|(Likely 7-day)|(Predicted 28-day)/
                    )
                )
                .map((item) => {
                  return {
                    name: item.dimensionValues[0].value,
                    value: item.metricValues[0].value,
                  };
                });
              console.log("14. checkAudience", data_audience);
              setAudience(data_audience);
              let data_predicted = response.result.rows
                .filter((item) =>
                  item.dimensionValues[0].value.match(
                    /(未來 7 天)|(接下來 28 天)|(Likely 7-day)|(Predicted 28-day)/
                  )
                )
                .map((item) => {
                  return {
                    name: item.dimensionValues[0].value,
                    value: item.metricValues[0].value,
                  };
                });
              console.log("15. checkPredicted", data_predicted);
              setPredicted(data_predicted);
              resolve(data_predicted);
            } else {
              console.log("14. checkAudience", []);
              setAudience([]);
              console.log("15. checkPredicted", []);
              setPredicted([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("checkAudience error", err);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkEccomerce = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsdata.properties
        .runReport({
          property: property,
          resource: {
            dimensions: [
              {
                name: "eventName",
              },
            ],
            metrics: [
              {
                name: "eventCount",
              },
            ],
            dateRanges: [
              {
                startDate: "30daysAgo",
                endDate: "yesterday",
              },
            ],
          },
        })
        .then(
          function (response) {
            if (response.result.rows) {
              let data = [
                "add_payment_info",
                "add_shipping_info",
                "add_to_cart",
                "add_to_wishlist",
                "begin_checkout",
                "in_app_purchase",
                "purchase",
                "refund",
                "remove_from_cart",
                "select_item",
                "select_promotion",
                "view_cart",
                "view_item",
                "view_item_list",
                "view_promotion",
              ]
                .map((event) => {
                  const item = response.result.rows.find(
                    (item) => item.dimensionValues[0].value === event
                  );
                  if (item) {
                    return {
                      name: event,
                      value: item.metricValues[0].value,
                    };
                  } else {
                    return {
                      name: event,
                      value: "0",
                    };
                  }
                })
                .reduce(
                  (acc, cur) => {
                    if (
                      cur.name === "in_app_purchase" ||
                      cur.name === "purchase"
                    ) {
                      acc.find((item) => item.name === "purchase").value =
                        String(
                          Number(
                            acc.find((item) => item.name === "purchase").value
                          ) + Number(cur.value)
                        );
                    } else {
                      acc.push(cur);
                    }
                    return acc;
                  },
                  [
                    {
                      name: "purchase",
                      value: "0",
                    },
                  ]
                )
                .sort((a, b) =>
                  a.name < b.name ? -1 : a.name > b.name ? 1 : 0
                );
              console.log("16. checkEccomerce", data);

              setEccomerce(data);
              resolve(data);
            } else {
              console.log(
                "16. checkEccomerce",
                [
                  "add_payment_info",
                  "add_shipping_info",
                  "add_to_cart",
                  "add_to_wishlist",
                  "begin_checkout",
                  "in_app_purchase",
                  "purchase",
                  "refund",
                  "remove_from_cart",
                  "select_item",
                  "select_promotion",
                  "view_cart",
                  "view_item",
                  "view_item_list",
                  "view_promotion",
                ].map((item) => ({ name: item, value: 0 }))
              );
              setEccomerce(
                [
                  "add_payment_info",
                  "add_shipping_info",
                  "add_to_cart",
                  "add_to_wishlist",
                  "begin_checkout",
                  "in_app_purchase",
                  "purchase",
                  "refund",
                  "remove_from_cart",
                  "select_item",
                  "select_promotion",
                  "view_cart",
                  "view_item",
                  "view_item_list",
                  "view_promotion",
                ].map((item) => ({ name: item, value: 0 }))
              );
              resolve(
                [
                  "add_payment_info",
                  "add_shipping_info",
                  "add_to_cart",
                  "add_to_wishlist",
                  "begin_checkout",
                  "in_app_purchase",
                  "purchase",
                  "refund",
                  "remove_from_cart",
                  "select_item",
                  "select_promotion",
                  "view_cart",
                  "view_item",
                  "view_item_list",
                  "view_promotion",
                ].map((item) => ({ name: item, value: 0 }))
              );
            }
          },
          function (err) {
            console.error("checkEccomerce error", err);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkMedium = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsdata.properties
        .runReport({
          property: property,
          resource: {
            dimensions: [
              {
                name: "sessionMedium",
              },
            ],
            metrics: [
              {
                name: "sessions",
              },
            ],
            dateRanges: [
              {
                startDate: "30daysAgo",
                endDate: "yesterday",
              },
            ],
          },
        })
        .then(
          function (response) {
            if (response.result.rows) {
              let data = response.result.rows.reduce(
                (acc, cur) => {
                  switch (cur.dimensionValues[0].value) {
                    case "organic": {
                      acc.organic += Number(cur.metricValues[0].value);
                      break;
                    }
                    case "(none)": {
                      acc.none += Number(cur.metricValues[0].value);
                      break;
                    }
                    case "referral": {
                      acc.referral += Number(cur.metricValues[0].value);
                      break;
                    }
                    default: {
                      acc.others += Number(cur.metricValues[0].value);
                      break;
                    }
                  }
                  acc.session += Number(cur.metricValues[0].value);
                  return acc;
                },
                { organic: 0, none: 0, referral: 0, others: 0, session: 0 }
              );
              data = Object.entries(data).reduce((acc, cur) => {
                if (cur[0] !== "session") {
                  acc.push({
                    name: cur[0],
                    value: ((cur[1] / data.session) * 100).toFixed(2),
                  });
                }
                return acc;
              }, []);

              console.log("17. checkMedium", data);

              setMedium(data);
              resolve(data);
            } else {
              console.log("17. checkMedium", []);
              setMedium([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("checkMedium error", err);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkWebDataStreamsMeasurementProtocol = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties.webDataStreams
        .list({
          parent: property,
        })
        .then(
          function (response) {
            if (response.result.webDataStreams) {
              Promise.all(
                response.result.webDataStreams.map((item) => {
                  return new Promise((resolve, reject) => {
                    gapi.client.analyticsadmin.properties.webDataStreams.measurementProtocolSecrets
                      .list({
                        parent: item.name,
                      })
                      .then(
                        function (response) {
                          resolve(response.result);
                        },
                        function (err) {
                          console.error("Execute error", err);
                          resolve({});
                        }
                      );
                  });
                })
              ).then((values) => {
                var data = values
                  .filter((item) => Object.keys(item).length)
                  .map((item) => item.measurementProtocolSecrets)
                  .reduce((acc, cur) => {
                    cur.map((item) => {
                      acc.push({
                        type: "Web",
                        name: item.displayName,
                        key: item.secretValue,
                      });
                    });
                    return acc;
                  }, []);
                console.log("19. webDataStreamsMeasurementProtocol", data);
                setWebDataStreamsMeasurementProtocol(data);
                resolve(data);
              });
            } else {
              console.log("19. webDataStreamsMeasurementProtocol", []);
              setWebDataStreamsMeasurementProtocol([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("19. webDataStreamsMeasurementProtocol", err);
            setWebDataStreamsMeasurementProtocol([]);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkAndroidAppDataStreamsMeasurementProtocol = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties.androidAppDataStreams
        .list({
          parent: property,
        })
        .then(
          function (response) {
            if (response.result.androidAppDataStreams) {
              Promise.all(
                response.result.androidAppDataStreams.map((item) => {
                  return new Promise((resolve, reject) => {
                    gapi.client.analyticsadmin.properties.androidAppDataStreams.measurementProtocolSecrets
                      .list({
                        parent: item.name,
                      })
                      .then(
                        function (response) {
                          resolve(response.result);
                        },
                        function (err) {
                          console.error("Execute error", err);
                          resolve({});
                        }
                      );
                  });
                })
              ).then((values) => {
                var data = values
                  .filter((item) => Object.keys(item).length)
                  .map((item) => item.measurementProtocolSecrets)
                  .reduce((acc, cur) => {
                    cur.map((item) => {
                      acc.push({
                        type: "Android",
                        name: item.displayName,
                        key: item.secretValue,
                      });
                    });
                    return acc;
                  }, []);
                console.log("19. androidDataStreamsMeasurementProtocol", data);
                setAndroidAppDataStreamsMeasurementProtocol(data);
                resolve(data);
              });
            } else {
              console.log("19. androidDataStreamsMeasurementProtocol", []);
              setAndroidAppDataStreamsMeasurementProtocol([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("19. androidDataStreamsMeasurementProtocol", err);
            setAndroidAppDataStreamsMeasurementProtocol([]);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkIosAppDataStreamsMeasurementProtocol = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties.iosAppDataStreams
        .list({
          parent: property,
        })
        .then(
          function (response) {
            if (response.result.iosAppDataStreams) {
              Promise.all(
                response.result.iosAppDataStreams.map((item) => {
                  return new Promise((resolve, reject) => {
                    gapi.client.analyticsadmin.properties.iosAppDataStreams.measurementProtocolSecrets
                      .list({
                        parent: item.name,
                      })
                      .then(
                        function (response) {
                          resolve(response.result);
                        },
                        function (err) {
                          console.error("Execute error", err);
                          resolve({});
                        }
                      );
                  });
                })
              ).then((values) => {
                var data = values
                  .filter((item) => Object.keys(item).length)
                  .map((item) => item.measurementProtocolSecrets)
                  .reduce((acc, cur) => {
                    cur.map((item) => {
                      item.type = "Ios";
                      acc.push({
                        type: "Ios",
                        name: item.displayName,
                        key: item.secretValue,
                      });
                    });
                    return acc;
                  }, []);
                console.log("19. iosDataStreamsMeasurementProtocol", data);
                setIosAppDataStreamsMeasurementProtocol(data);
                resolve(data);
              });
            } else {
              console.log("19. iosDataStreamsMeasurementProtocol", []);
              setIosAppDataStreamsMeasurementProtocol([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("19. iosDataStreamsMeasurementProtocol", err);
            setIosAppDataStreamsMeasurementProtocol([]);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkGoogleCPCTrans = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsdata.properties
        .runReport({
          property: property,
          resource: {
            dimensions: [{ name: "sessionSource" }, { name: "sessionMedium" }],
            metrics: [{ name: "eventCount" }],
            dimensionFilter: {
              andGroup: {
                expressions: [
                  {
                    filter: {
                      fieldName: "isConversionEvent",
                      stringFilter: {
                        matchType: "EXACT",
                        value: "true",
                      },
                    },
                  },
                ],
              },
            },
            dateRanges: [
              {
                startDate: "30daysAgo",
                endDate: "yesterday",
              },
            ],
            limit: 100000,
          },
        })
        .then(
          function (response) {
            if (response.result.rows) {
              console.log(response);

              var total = response.result.rows.reduce((acc, cur) => {
                acc = acc += parseInt(cur.metricValues[0].value, 10);
                return acc;
              }, 0);

              var googleCpc = response.result.rows.reduce((acc, cur) => {
                if (
                  cur.dimensionValues[0].value === "google" &&
                  cur.dimensionValues[1].value === "cpc"
                ) {
                  acc = acc += parseInt(cur.metricValues[0].value, 10);
                }
                return acc;
              }, 0);

              var data = Math.floor((googleCpc / total) * 100);

              console.log("20. checkGoogleCPCTrans", googleCpc, total, data);

              setGoogleCPCTrans(data);
              resolve(data);
            } else {
              console.log("20. checkGoogleCPCTrans", []);
              setGoogleCPCTrans([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("checkGoogleCPCTrans error", err);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkUnassigned = () => {
    return new Promise((resolve, reject) => {
      gapi.client.analyticsdata.properties
        .runReport({
          property: property,
          resource: {
            dimensions: [
              { name: "sessionDefaultChannelGrouping" },
              { name: "sessionMedium" },
              { name: "sessionSource" },
            ],
            metrics: [{ name: "totalUsers" }],
            dateRanges: [{ startDate: "90daysAgo", endDate: "yesterday" }],
            metricAggregations: ["TOTAL"],
          },
        })
        .then(
          function (response) {
            console.log(response);
            if (response.result.rows) {
              var total = response.result.rows.reduce((acc, cur) => {
                acc = acc += parseInt(cur.metricValues[0].value, 10);
                return acc;
              }, 0);
              var unassigned = response.result.rows.reduce((acc, cur) => {
                if (
                  cur.dimensionValues[0].value === "Unassigned" &&
                  cur.dimensionValues[1].value === "(not set)" &&
                  cur.dimensionValues[2].value === "(not set)"
                ) {
                  acc = acc += parseInt(cur.metricValues[0].value, 10);
                }
                return acc;
              }, 0);
              var data = Math.floor((unassigned / total) * 100);

              console.log("21. checkUnassigned", data, unassigned, total);
              setUnassigned(data);
              resolve(data);
            } else {
              console.log("21. checkUnassigned", 0);
              setUnassigned(0);
              resolve(0);
            }
          },
          function (err) {
            console.error("21. checkUnassigned error", err);
            setUnassigned(0);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };

  const checkConversionEventsDefault = () => {
    return new Promise((resolve, reject) => {
      window.gapi.client.analyticsadmin.properties.conversionEvents
        .list({
          parent: property,
        })
        .then(
          function (response) {
            if (response.result.conversionEvents) {
              let data = response.result.conversionEvents
                .filter((item) => {
                  return [
                    "session_start",
                    "scroll",
                    "page_view",
                    "first_visit",
                    "click",
                    "app_clear_data",
                    "app_exception",
                    "app_remove",
                    "app_store_refund",
                    "app_store_subscription_cancel",
                    "dynamic_link_app_open",
                    "dynamic_link_app_update",
                    "first_open",
                    "first_visit",
                    "notification_dismiss",
                    "notification_foreground",
                    "notification_open",
                    "notification_receive",
                    "os_update",
                    "screen_view",
                  ].find((event) => item.eventName === event);
                })
                .map((item) => item.eventName);
              console.log("22. checkConversionEventsDefault", data);
              setConversionEventsDefault(data);
              resolve(data);
            } else {
              console.log("22. checkConversionEventsDefault", []);
              setConversionEventsDefault([]);
              resolve([]);
            }
          },
          function (err) {
            console.error("checkConversionEventsDefault error", err);
            setGoogleApiFalse(true);
            reject(err);
          }
        );
    });
  };
  /* api end */

  const resetTableData = () => {
    setDataStreams(null);
    // setWebDataStreams(null)
    // setAndroidAppDataStreams(null)
    // setIosAppDataStreams(null)
    setHostName(null);
    setCrossDomain(null);
    setIndustryCategory(null);
    setCurrencyCode(null);
    setTimeZone(null);
    setGa360(null);
    setGoogleSignalsSettings(null);
    setDataRetentionSettings(null);
    setUserProperties(null);
    setGoogleAdsLinks(null);
    setAudience(null);
    setPredicted(null);
    setInteractionRate(null);
    setConversionEvents(null);
    setEccomerce(null);
    setMedium(null);
    setUserId(null);
    setScore(0);
    setPrScore(0);
    // setWebDataStreamsMeasurementProtocol(null)
    // setAndroidAppDataStreamsMeasurementProtocol(null)
    // setIosAppDataStreamsMeasurementProtocol(null)
    setGoogleCPCTrans(null);
    setUnassigned(null);

    setConversionEventsDefault(null);

    setCheckListResult(checkListInit);
  };

  const getStatusIcon = (name) => {
    if (name === "尚未檢核") {
      const color = "#000";

      return (
        <>
          <MinusCircleFilled style={{ color: color, marginRight: "8px" }} />
          <span style={{ color: color }}>{name}</span>
        </>
      );
    } else if (name === "建議修正") {
      const color = "#fdb900";

      return (
        <>
          <WarningFilled style={{ color: color, marginRight: "8px" }} />
          <span style={{ color: color }}>{name}</span>
        </>
      );
    } else if (name === "數據異常") {
      const color = "#f90000";

      return (
        <>
          <CloseCircleFilled style={{ color: color, marginRight: "8px" }} />
          <span style={{ color: color }}>{name}</span>
        </>
      );
    } else if (name === "正常運作") {
      const color = "#7fb900";

      return (
        <>
          <CheckCircleFilled style={{ color: color, marginRight: "8px" }} />
          <span style={{ color: color }}>{name}</span>
        </>
      );
    }
  };

  const openSuccessModal = () => {
    Modal.success({
      content: "表單送出成功！我們會同步發送一封信件，請確認信箱內容！",
    });
  };

  const openNoticeModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const timesWaringModalOk = () => {
    utilsIndex.sendGAEvent("come_back_tomorrow");
    setIsTimesWaringModalVisible(false);
  };

  const timesWaringModalCancel = () => {
    setIsTimesWaringModalVisible(false);
  };

  const timesWaringModalAnswerNo = () => {
    utilsIndex.sendGAEvent("want_some_help");
    window.open("https://www.turingdigital.com.tw/chek-website");
  };

  const starModalOk = () => {
    utilsIndex.sendGAEvent("i_have_GA4");
    docCookies.setItem("startModalCancel_Infinity", 1, Infinity, "/");
    setIsStarModalVisible(false);
  };

  const starModalCancel = () => {
    utilsIndex.sendGAEvent("i_dont_know");
    setIsStarModalVisible(false);
  };

  const starModalAnswerNo = () => {
    utilsIndex.sendGAEvent("i_dont_have_GA4");
    window.open("https://www.turingdigital.com.tw/start-import-ga4");
    setIsStarModalVisible(false);
  };

  const handleSubmitContactForm = (values) => {
    if (!isSubmitContactForm) {
      setIsSubmitContactForm(true);

      utilsIndex.sendGAEvent("submit");
      // openNotification()

      // setCheckListSummaries({
      //   ...checkListSummaries,
      //   Contact: true,
      //   Contact_Name: values.contact.name,
      //   Contact_Phone: values.contact.phone,
      //   Contact_Email: values.contact.email,
      //   Contact_Others: values.contact.others,
      //   // Contact_Items: [],
      //   Contact_Time: values.contact.time,
      // })

      // let apiData = Object.assign({}, checkListSummaries)

      const checkListResultError = checkListResult.filter((item) => {
        if (item.status == "數據異常" || item.status == "建議修正") {
          return true;
        }
      });

      const items = checkListResultError.map((item) => {
        return {
          name: item.name.tw,
          value: item.status,
        };
      });

      let apiData = {
        main: {
          ...checkListSummaries.main,
          Contact: true,
          Contact_Name: values.contact.name,
          Contact_Phone: values.contact.phone,
          Contact_Email: values.contact.email,
          Contact_Others: values.contact.others,
          Contact_Items: items,
          Contact_Time: Array.isArray(values.contact.time)
            ? values.contact.time
            : [],
        },
      };

      console.log("apiData submit = ", apiData);

      setCheckListSummaries(apiData);

      // 本地測試不用送 api
      if (location.hostname !== "localhost") {
        Modal.success({
          content: "表單送出中請稍候！",
        });

        utilsIndex.sendGAEvent("form_submit", {
          turing_01: "服務表單",
          turing_02: "Check Assistant頁",
        });

        axios
          .post(
            `https://freeanalysisapi.turingdigital.com.tw/mains_batch/${apiId}`,
            apiData
          )
          .then(function (response) {
            // console.log("mains_batch submit = ", response)
            Modal.destroyAll();
            openSuccessModal();
          })
          .catch(function (error) {
            console.log("mains_batch submit error = ", error);
          });
      }
    }
  };

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);

  /* score */
  const calcNumber = () => {
    let number = 100;
    dataStreams;
    if (
      dataStreams.length
      // webDataStreams.length ||
      // iosAppDataStreams.length ||
      // androidAppDataStreams.length
    ) {
    } else {
      number -= 15;
    }
    // hostName
    if (hostName.length) {
    } else {
      number -= 15;
    }
    // crossDomain
    if (hostName.length >= 2 && crossDomain) {
    } else if (hostName.length >= 2 && !crossDomain) {
      number -= 5;
    } else if (hostName.length == 1 && !crossDomain) {
    } else if (!hostName.length) {
      number -= 5;
    }
    // industryCategory
    if (industryCategory) {
    } else {
      number -= 15;
    }
    // timeZone
    if (timeZone) {
    } else {
      number -= 10;
    }
    // googleSignalsSettings
    if (googleSignalsSettings !== "GOOGLE_SIGNALS_ENABLED") {
      number -= 15;
    }
    // dataRetentionSettings
    if (dataRetentionSettings !== "FOURTEEN_MONTHS") {
      number -= 15;
    }
    // userProperties
    if (!userProperties.length) {
      number -= 0;
    }
    // googleAdsLinks
    if (!googleAdsLinks.length) {
      number -= 0;
    }
    // interactionRate
    if (Number(interactionRate) < 80) {
      number -= 5;
    } else if (Number(interactionRate) > 100) {
      number -= 10;
    }
    // 非預設轉換事件
    if (!conversionEvents.length) {
      number -= 15;
    }
    // audience
    if (!audience.length) {
      number -= 5;
    }
    // predicted
    if (!predicted.length) {
      number -= 5;
    }
    // eccomerce
    if (
      eccomerce
        .filter((item) => Number(item.value) === 0)
        .filter((item) => item.name !== "purchase").length
    ) {
      number -= 15;
    }
    // medium
    if (!medium.length) {
      number -= 5;
    }
    // userid
    if (!userId) {
      number -= 0;
    }

    // Measurement Protocol (不扣分)
    // if (
    //   !webDataStreamsMeasurementProtocol.length &&
    //   !androidAppDataStreamsMeasurementProtocol.length &&
    //   !iosAppDataStreamsMeasurementProtocol.length
    // ) {
    //   number -= 8
    // }

    // Google Ads 轉換成效 (不扣分)
    // if (!googleCPCTrans.length) {
    //   number -= 8
    // }

    if (unassigned >= 2) {
      number -= 5;
    }

    setScore(number <= 0 ? 1 : number);
  };

  useEffect(() => {
    if (
      dataStreams !== null &&
      // webDataStreams !== null &&
      // androidAppDataStreams !== null &&
      // iosAppDataStreams !== null &&
      hostName !== null &&
      crossDomain !== null &&
      industryCategory !== null &&
      currencyCode !== null &&
      timeZone !== null &&
      googleSignalsSettings !== null &&
      dataRetentionSettings !== null &&
      userProperties !== null &&
      googleAdsLinks !== null &&
      audience !== null &&
      predicted !== null &&
      interactionRate !== null &&
      conversionEvents !== null &&
      eccomerce !== null &&
      medium !== null &&
      // webDataStreamsMeasurementProtocol !== null &&
      // androidAppDataStreamsMeasurementProtocol !== null &&
      // iosAppDataStreamsMeasurementProtocol !== null &&
      googleCPCTrans !== null &&
      unassigned !== null
    ) {
      console.log("userId ======", userId);
      // 計算分數
      calcNumber();
    }
  }, [
    dataStreams,
    // webDataStreams,
    // androidAppDataStreams,
    // iosAppDataStreams,
    hostName,
    crossDomain,
    industryCategory,
    currencyCode,
    timeZone,
    googleSignalsSettings,
    dataRetentionSettings,
    userProperties,
    googleAdsLinks,
    interactionRate,
    conversionEvents,
    audience,
    predicted,
    eccomerce,
    medium,
    userId,
    // webDataStreamsMeasurementProtocol,
    // androidAppDataStreamsMeasurementProtocol,
    // iosAppDataStreamsMeasurementProtocol,
    googleCPCTrans,
    unassigned,
  ]);

  const getCheckListItemStatus = (pItem) => {
    switch (pItem.type) {
      case "checkProperty": {
        if (property) {
          return (
            <>
              <CheckCircleFilled
                style={{ color: "#7fb900", marginRight: "8px" }}
              />
              <span style={{ color: "#7fb900" }}>正常運作</span>
            </>
          );
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkDataStreams": {
        if (
          dataStreams !== null
          // webDataStreams !== null &&
          // iosAppDataStreams !== null &&
          // androidAppDataStreams !== null
        ) {
          if (
            dataStreams.length
            // webDataStreams.length ||
            // iosAppDataStreams.length ||
            // androidAppDataStreams.length
          ) {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          } else {
            return (
              <>
                <CloseCircleFilled
                  style={{ color: "#f90000", marginRight: "8px" }}
                />
                <span style={{ color: "#f90000" }}>數據異常</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkHostName": {
        if (
          hostName !== null &&
          dataStreams !== null
          // webDataStreams !== null &&
          // iosAppDataStreams !== null &&
          // androidAppDataStreams !== null
        ) {
          if (hostName.length) {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          } else if (
            !hostName.length &&
            dataStreams.length
            // (webDataStreams.length ||
            //   iosAppDataStreams.length ||
            //   androidAppDataStreams.length)
          ) {
            // return <span style={{ color: "#f90000" }}>查無網域</span>
            return (
              <>
                <CloseCircleFilled
                  style={{ color: "#f90000", marginRight: "8px" }}
                />
                <span style={{ color: "#f90000" }}>數據異常</span>
              </>
            );
          } else {
            return (
              <>
                <CloseCircleFilled
                  style={{ color: "#f90000", marginRight: "8px" }}
                />
                <span style={{ color: "#f90000" }}>數據異常</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkCrossDomain": {
        if (crossDomain !== null) {
          if (crossDomain) {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          } else {
            return (
              <>
                <CloseCircleFilled
                  style={{ color: "#f90000", marginRight: "8px" }}
                />
                <span style={{ color: "#f90000" }}>數據異常</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkIndustryCategory": {
        if (industryCategory !== null) {
          if (industryCategory) {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          } else {
            return (
              <>
                <CloseCircleFilled
                  style={{ color: "#f90000", marginRight: "8px" }}
                />
                <span style={{ color: "#f90000" }}>數據異常</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkTimeZone": {
        if (timeZone !== null) {
          return (
            <>
              <CheckCircleFilled
                style={{ color: "#7fb900", marginRight: "8px" }}
              />
              <span style={{ color: "#7fb900" }}>正常運作</span>
            </>
          );
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkCurrencyCode": {
        if (currencyCode !== null) {
          return (
            <>
              <CheckCircleFilled
                style={{ color: "#7fb900", marginRight: "8px" }}
              />
              <span style={{ color: "#7fb900" }}>正常運作</span>
            </>
          );
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkGoogleSignalsSettings": {
        if (googleSignalsSettings !== null) {
          if (googleSignalsSettings !== "GOOGLE_SIGNALS_ENABLED") {
            return (
              <>
                <CloseCircleFilled
                  style={{ color: "#f90000", marginRight: "8px" }}
                />
                <span style={{ color: "#f90000" }}>數據異常</span>
              </>
            );
          } else {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkDataRetentionSettings": {
        if (dataRetentionSettings !== null) {
          if (dataRetentionSettings !== "FOURTEEN_MONTHS") {
            return (
              <>
                <WarningFilled
                  style={{ color: "#fdb900", marginRight: "8px" }}
                />
                <span style={{ color: "#fdb900" }}>建議修正</span>
              </>
            );
          } else {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkUserProperties": {
        if (userProperties !== null) {
          if (!userProperties.length) {
            return (
              <>
                <WarningFilled
                  style={{ color: "#fdb900", marginRight: "8px" }}
                />
                <span style={{ color: "#fdb900" }}>建議修正</span>
              </>
            );
          } else {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkGoogleAdsLinks": {
        if (googleAdsLinks !== null) {
          if (!googleAdsLinks.length) {
            return (
              <>
                <WarningFilled
                  style={{ color: "#fdb900", marginRight: "8px" }}
                />
                <span style={{ color: "#fdb900" }}>建議修正</span>
              </>
            );
          } else {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkInteractionRate": {
        if (interactionRate !== null) {
          if (Number(interactionRate) < 80) {
            return (
              <>
                <WarningFilled
                  style={{ color: "#fdb900", marginRight: "8px" }}
                />
                <span style={{ color: "#fdb900" }}>建議修正</span>
              </>
            );
          } else {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkConversionEvents": {
        if (conversionEvents !== null) {
          if (!conversionEvents.length) {
            return (
              <>
                <WarningFilled
                  style={{ color: "#fdb900", marginRight: "8px" }}
                />
                <span style={{ color: "#fdb900" }}>建議修正</span>
              </>
            );
          } else {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkAudience": {
        if (audience !== null) {
          if (!audience.length) {
            return (
              <>
                <WarningFilled
                  style={{ color: "#fdb900", marginRight: "8px" }}
                />
                <span style={{ color: "#fdb900" }}>建議修正</span>
              </>
            );
          } else {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkPredicted": {
        if (predicted !== null) {
          if (!predicted.length) {
            return (
              <>
                <WarningFilled
                  style={{ color: "#fdb900", marginRight: "8px" }}
                />
                <span style={{ color: "#fdb900" }}>建議修正</span>
              </>
            );
          } else {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkEccomerce": {
        if (eccomerce !== null) {
          if (eccomerce.filter((item) => Number(item.value) === 0).length) {
            return (
              <>
                <CloseCircleFilled
                  style={{ color: "#f90000", marginRight: "8px" }}
                />
                <span style={{ color: "#f90000" }}>數據異常</span>
              </>
            );
          } else {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkMedium": {
        if (medium !== null) {
          if (!medium.length) {
            return (
              <>
                <CloseCircleFilled
                  style={{ color: "#f90000", marginRight: "8px" }}
                />
                <span style={{ color: "#f90000" }}>數據異常</span>
              </>
            );
          } else {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkUserId": {
        if (userId !== null) {
          if (userId) {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          } else {
            return (
              <>
                <WarningFilled
                  style={{ color: "#fdb900", marginRight: "8px" }}
                />
                <span style={{ color: "#fdb900" }}>建議修正</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkDataStreamsMeasurementProtocol": {
        if (
          webDataStreamsMeasurementProtocol !== null &&
          androidAppDataStreamsMeasurementProtocol !== null &&
          iosAppDataStreamsMeasurementProtocol !== null
        ) {
          var status = pItem.status;
          // console.log("checkDataStreamsMeasurementProtocol status = ", status)

          return <>{getStatusIcon(status)}</>;
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkGoogleCPCTrans": {
        if (googleCPCTrans !== null) {
          var status = pItem.status;
          // console.log("checkGoogleCPCTrans status = ", status)

          return <>{getStatusIcon(status)}</>;
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      case "checkUnassigned": {
        if (unassigned !== null) {
          if (unassigned === 0) {
            return (
              <>
                <CheckCircleFilled
                  style={{ color: "#7fb900", marginRight: "8px" }}
                />
                <span style={{ color: "#7fb900" }}>正常運作</span>
              </>
            );
          } else {
            return (
              <>
                <CloseCircleFilled
                  style={{ color: "#f90000", marginRight: "8px" }}
                />
                <span style={{ color: "#f90000" }}>數據異常</span>
              </>
            );
          }
        } else {
          return (
            <>
              <MinusCircleFilled />
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).status
              }
            </>
          );
        }
      }
      default: {
        return (
          <>
            <MinusCircleFilled />
            {
              checkListResult.filter(function (item, index, array) {
                return item.type === pItem.type;
              }).status
            }
          </>
        );
      }
    }
  };
  const getCheckListItemResult = (pItem) => {
    switch (pItem.type) {
      case "checkProperty": {
        if (property) {
          return <>{property}</>;
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkDataStreams": {
        if (
          dataStreams !== null
          // webDataStreams !== null &&
          // iosAppDataStreams !== null &&
          // androidAppDataStreams !== null
        ) {
          if (
            dataStreams.length
            // webDataStreams.length ||
            // iosAppDataStreams.length ||
            // androidAppDataStreams.length
          ) {
            return (
              <>
                {dataStreams.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
                {/*
                {webDataStreams.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
                {iosAppDataStreams.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
                {androidAppDataStreams.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              */}
              </>
            );
          } else {
            return <span style={{ color: "#f90000" }}>未設定</span>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkHostName": {
        if (
          hostName !== null &&
          dataStreams !== null
          // webDataStreams !== null &&
          // iosAppDataStreams !== null &&
          // androidAppDataStreams !== null
        ) {
          if (hostName.length) {
            return (
              <>
                {hostName.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </>
            );
          } else if (
            !hostName.length &&
            dataStreams.length
            // (webDataStreams.length ||
            //   iosAppDataStreams.length ||
            //   androidAppDataStreams.length)
          ) {
            return <span style={{ color: "#f90000" }}>查無網域</span>;
          } else {
            return <span style={{ color: "#f90000" }}>未設定</span>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkCrossDomain": {
        if (crossDomain !== null) {
          return (
            <>
              {crossDomain ? (
                "有跨網追蹤"
              ) : (
                <div>
                  無跨網追蹤（若剛設定完畢，可能會有數據延遲，請耐心等候至少24小時）
                  <p style={{ color: "#f90000" }}>
                    請注意！只要在「追蹤網域數量項目」檢測超過 1
                    個以上的網域，但是沒有偵測到跨網域追蹤，就會被判定為異常。
                  </p>
                </div>
              )}
            </>
          );
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkIndustryCategory": {
        if (industryCategory !== null) {
          if (industryCategory) {
            return <>{industryCategory}</>;
          } else {
            return <span style={{ color: "#f90000" }}>未設定</span>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkTimeZone": {
        if (timeZone !== null) {
          return <>{timeZone}</>;
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkCurrencyCode": {
        if (currencyCode !== null) {
          return <>{currencyCode}</>;
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkGoogleSignalsSettings": {
        if (googleSignalsSettings !== null) {
          if (googleSignalsSettings) {
            return <>{googleSignalsSettings}</>;
          } else {
            return <span style={{ color: "#f90000" }}>未設定</span>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkDataRetentionSettings": {
        if (dataRetentionSettings !== null) {
          return (
            <>
              {ga360 ? "360 | " : ""} {dataRetentionSettings}
            </>
          );
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkUserProperties": {
        if (userProperties !== null) {
          if (userProperties.length) {
            return (
              <>
                {userProperties.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </>
            );
          } else {
            return <span style={{ color: "#f90000" }}>未設定</span>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkGoogleAdsLinks": {
        if (googleAdsLinks !== null) {
          if (googleAdsLinks.length) {
            return (
              <>
                {googleAdsLinks.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </>
            );
          } else {
            return <span style={{ color: "#f90000" }}>未串接</span>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkInteractionRate": {
        if (interactionRate !== null) {
          return <>{interactionRate}%</>;
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkConversionEvents": {
        if (conversionEvents !== null) {
          if (conversionEvents.length) {
            return (
              <>
                {conversionEvents.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </>
            );
          } else {
            return <span style={{ color: "#f90000" }}>未設定</span>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkAudience": {
        if (audience !== null) {
          if (audience.length) {
            return (
              <>
                {audience.map((item, index) => (
                  <div key={index}>
                    <Tag
                      color={Number(item.value) ? "#87d068" : "#f90000"}
                      style={{ width: "96px", textAlign: "center" }}
                    >
                      {item.value}
                    </Tag>{" "}
                    {item.name}
                  </div>
                ))}
              </>
            );
          } else {
            return <span style={{ color: "#f90000" }}>未設定</span>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkPredicted": {
        if (predicted !== null) {
          if (predicted.length) {
            return (
              <>
                {predicted.map((item, index) => (
                  <div key={index}>
                    <Tag
                      color={Number(item.value) ? "#87d068" : "#f90000"}
                      style={{ width: "96px", textAlign: "center" }}
                    >
                      {item.value}
                    </Tag>{" "}
                    {item.name}
                  </div>
                ))}
              </>
            );
          } else {
            return <span style={{ color: "#f90000" }}>未啟用</span>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkEccomerce": {
        if (eccomerce !== null) {
          return (
            <>
              {eccomerce.map((item, index) => (
                <div key={index}>
                  <Tag
                    color={Number(item.value) ? "#87d068" : "#f90000"}
                    style={{ width: "96px", textAlign: "center" }}
                  >
                    {item.value}
                  </Tag>{" "}
                  {item.name}
                </div>
              ))}
            </>
          );
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkMedium": {
        if (medium !== null) {
          if (medium.length) {
            return (
              <>
                {medium.map((item, index) => (
                  <div key={index}>
                    <Tag
                      color="#87d068"
                      style={{ width: "96px", textAlign: "center" }}
                    >
                      {item.value + "%"}
                    </Tag>{" "}
                    {item.name}
                  </div>
                ))}
              </>
            );
          } else {
            return <span style={{ color: "#f90000" }}>未設定</span>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkUserId": {
        if (userId !== null) {
          if (userId) {
            return <span>已設定</span>;
          } else {
            return <span>未設定</span>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkDataStreamsMeasurementProtocol": {
        if (
          webDataStreamsMeasurementProtocol !== null &&
          androidAppDataStreamsMeasurementProtocol !== null &&
          iosAppDataStreamsMeasurementProtocol !== null
        ) {
          var result = pItem.result;
          console.log("checkDataStreamsMeasurementProtocol result = ", result);

          if (result != "未申請") {
            return (
              <>
                {pItem.value.map((item, index) => (
                  <div key={index}>{item.key}</div>
                ))}
              </>
            );
          } else {
            return <>{result}</>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkGoogleCPCTrans": {
        if (googleCPCTrans !== null) {
          var result = pItem.result;
          // console.log("googleCPCTrans result = ", result);

          if (googleCPCTrans) {
            return <>{googleCPCTrans}%</>;
          } else {
            return <>{result}</>;
          }
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      case "checkUnassigned": {
        if (unassigned !== null) {
          return <>{unassigned === 0 ? 0 : unassigned}%</>;
        } else {
          return (
            <>
              {
                checkListResult.filter(function (item, index, array) {
                  return item.type === pItem.type;
                }).result
              }
            </>
          );
        }
      }
      default: {
        return (
          <>
            {
              checkListResult.filter(function (item, index, array) {
                return item.type === pItem.type;
              }).result
            }
          </>
        );
      }
    }
  };

  useEffect(() => {
    if (score) {
      console.log("!!!!!!!!!!!!!", score);
      setEchartsOption(getEchartsOption(score));
      setIsCheckApiFetchFinished(true);
      console.log("checkListResult = ", checkListResult);

      const DataStreams = checkListResult.find(function (item) {
        return item.type === "checkDataStreams";
      }).value;
      const MeasurementProtocol = checkListResult.find(function (item) {
        return item.type === "checkDataStreamsMeasurementProtocol";
      }).value;

      const apiData = {
        main: {
          TimeStamp: moment().format("YYYY-MM-DD HH:mm:ss"),
          PropertyID: property,
          CrossDomain: crossDomain,
          IndustryCategory: industryCategory,
          TimeZone: timeZone,
          Currency: currencyCode,
          GoogleSignals: googleSignalsSettings,
          DataRetention: dataRetentionSettings,
          LoginEmail: google_user_email,
          Score: score,
          EngagementRate: interactionRate,
          IndataStatus: "UserSelect",
          Is360: ga360,
          DataStreams: DataStreams,
          Domains: hostName,
          UserProperties: userProperties,
          GoogleAdsLinking: googleAdsLinks,
          NondefConvEvents: conversionEvents,
          Audiences: audience,
          PredictiveAudiences: predicted,
          Eccomerce: eccomerce,
          Sessions: medium,
          MeasurementProtocol: MeasurementProtocol,
          googleAdsConversion: googleCPCTrans,
          Unassigned: unassigned,
          Contact: false,
          Contact_Name: "",
          Contact_Phone: "",
          Contact_Email: "",
          Contact_Others: "",
          Contact_Items: [],
          Contact_Time: [],
        },
      };

      console.log("apiData = ", apiData);

      setCheckListSummaries(apiData);

      // 本地測試不用送 api
      if (location.hostname !== "localhost") {
        axios
          .post(
            "https://freeanalysisapi.turingdigital.com.tw/mains_batch",
            apiData
          )
          .then(function (response) {
            console.log("mains_batch = ", response);
            setApiId(response.data.id);
            setPrScore(response.data.pr);
            setCookie("td-out_of_quota", response.data.out_of_quota, 0);

            if (response.data.out_of_quota) {
              setOutOfQuota("true");
            }
          })
          .catch(function (error) {
            console.log("mains_batch error = ", error);
          });
      }
    } else {
      setEchartsOption(getEchartsOption(0));
    }
  }, [score]);
  /* score end */

  useEffect(() => {
    // 表單送出
    if (checkListSummaries.Contact) {
      // console.log("checkListSummaries = ", checkListSummaries)
    }
  }, [checkListSummaries]);

  const [checkListResult, setCheckListResult] = useState(checkListInit);

  /* api result */
  useEffect(() => {
    // console.log("property 變更 = ", property)
    if (property !== "" || property !== null) {
      setGoogleApiFalse(false);
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkProperty") {
            if (property) {
              // console.log("property 111 = ", property)
              return {
                ...item,
                value: property,
                status: "正常運作",
                result: property,
              };
            } else {
              // console.log("property 222 = ", property)
              return {
                ...item,
                value: "",
                status: "數據異常",
                result: "",
              };
            }
          }
          return item;
        })
      );
    }
  }, [property]);
  useEffect(() => {
    if (
      dataStreams !== null
      // webDataStreams !== null &&
      // iosAppDataStreams !== null &&
      // androidAppDataStreams !== null
    ) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkDataStreams") {
            if (
              dataStreams.length
              // webDataStreams.length ||
              // iosAppDataStreams.length ||
              // androidAppDataStreams.length
            ) {
              return {
                ...item,
                // value: webDataStreams
                //   .concat(iosAppDataStreams)
                //   .concat(androidAppDataStreams),
                value: dataStreams,
                status: "正常運作",
                result: "",
              };
            } else {
              return {
                ...item,
                value: [],
                status: "建議修正",
                result: "未設定",
              };
            }
          }
          return item;
        })
      );
    }
  }, [
    dataStreams,
    // webDataStreams, androidAppDataStreams, iosAppDataStreams
  ]);
  useEffect(() => {
    if (
      hostName !== null &&
      dataStreams !== null
      // webDataStreams !== null &&
      // iosAppDataStreams !== null &&
      // androidAppDataStreams !== null
    ) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkHostName") {
            // console.log("hostName.length = ", hostName.length)
            if (hostName.length) {
              return {
                ...item,
                value: hostName,
                status: "正常運作",
                result: hostName,
              };
            } else if (
              !hostName.length &&
              dataStreams.length
              // (webDataStreams.length ||
              //   iosAppDataStreams.length ||
              //   androidAppDataStreams.length)
            ) {
              return {
                ...item,
                value: [],
                status: "建議修正",
                result: "查無網域",
              };
            } else {
              return {
                ...item,
                value: [],
                status: "建議修正",
                result: "未設定",
              };
            }
          }
          return item;
        })
      );
    }
  }, [hostName, dataStreams]);
  useEffect(() => {
    if (crossDomain !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkCrossDomain") {
            if (crossDomain) {
              return {
                ...item,
                value: true,
                status: "正常運作",
                result: "有跨網追蹤",
              };
            } else {
              return {
                ...item,
                value: true,
                status: "建議修正",
                result: "無跨網追蹤",
              };
            }
          }
          return item;
        })
      );
    }
  }, [crossDomain, hostName]);
  useEffect(() => {
    if (industryCategory !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkIndustryCategory") {
            if (industryCategory) {
              return {
                ...item,
                value: industryCategory,
                status: "正常運作",
                result: industryCategory,
              };
            } else {
              return {
                ...item,
                value: "",
                status: "建議修正",
                result: "未設定",
              };
            }
          }
          return item;
        })
      );
    }
  }, [industryCategory]);
  useEffect(() => {
    if (timeZone !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkTimeZone") {
            if (timeZone) {
              return {
                ...item,
                value: timeZone,
                status: "正常運作",
                result: timeZone,
              };
            } else {
              return {
                ...item,
                value: "",
                status: "建議修正",
                result: "未設定",
              };
            }
          }
          return item;
        })
      );
    }
  }, [timeZone]);
  useEffect(() => {
    if (currencyCode !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkCurrencyCode") {
            if (currencyCode) {
              return {
                ...item,
                value: currencyCode,
                status: "正常運作",
                result: currencyCode,
              };
            } else {
              return {
                ...item,
                value: "",
                status: "建議修正",
                result: "未設定",
              };
            }
          }
          return item;
        })
      );
    }
  }, [currencyCode]);
  useEffect(() => {
    if (googleSignalsSettings !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkGoogleSignalsSettings") {
            if (googleSignalsSettings === "GOOGLE_SIGNALS_STATE_UNSPECIFIED") {
              return {
                ...item,
                value: googleSignalsSettings,
                status: "數據異常",
                result: "未設定",
              };
            } else if (googleSignalsSettings === "GOOGLE_SIGNALS_DISABLED") {
              return {
                ...item,
                value: googleSignalsSettings,
                status: "建議修正",
                result: "未設定",
              };
            } else {
              return {
                ...item,
                value: googleSignalsSettings,
                status: "正常運作",
                result: googleSignalsSettings,
              };
            }
          }
          return item;
        })
      );
    }
  }, [googleSignalsSettings]);
  useEffect(() => {
    if (dataRetentionSettings !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkDataRetentionSettings") {
            if (
              dataRetentionSettings !== "FOURTEEN_MONTHS" ||
              dataRetentionSettings !== "FIFTY_MONTHS"
            ) {
              return {
                ...item,
                value: "",
                status: "建議修正",
                result: "未設定",
              };
            } else if (
              dataRetentionSettings !== "RETENTION_DURATION_UNSPECIFIED"
            ) {
              return {
                ...item,
                value: "",
                status: "數據異常",
                result: "未設定",
              };
            } else {
              return {
                ...item,
                value: (ga360 ? "360 | " : "") + dataRetentionSettings,
                status: "正常運作",
                result: (ga360 ? "360 | " : "") + dataRetentionSettings,
              };
            }
          }
          return item;
        })
      );
    }
  }, [dataRetentionSettings, ga360]);
  useEffect(() => {
    if (userProperties !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkUserProperties") {
            if (userProperties.length) {
              return {
                ...item,
                value: userProperties,
                status: "正常運作",
                result: userProperties,
              };
            } else {
              return {
                ...item,
                value: [],
                status: "建議修正",
                result: "未設定",
              };
            }
          }
          return item;
        })
      );
    }
  }, [userProperties]);
  useEffect(() => {
    if (googleAdsLinks !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkGoogleAdsLinks") {
            if (googleAdsLinks.length) {
              return {
                ...item,
                value: googleAdsLinks,
                status: "正常運作",
                result: googleAdsLinks,
              };
            } else {
              return {
                ...item,
                value: [],
                status: "建議修正",
                result: "未串接",
              };
            }
          }
          return item;
        })
      );
    }
  }, [googleAdsLinks]);
  useEffect(() => {
    if (interactionRate !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkInteractionRate") {
            if (Number(interactionRate) > 100) {
              return {
                ...item,
                value: interactionRate + "%",
                status: "數據異常",
                result: interactionRate + "%",
              };
            } else if (Number(interactionRate) >= 80) {
              return {
                ...item,
                value: interactionRate + "%",
                status: "正常運作",
                result: interactionRate + "%",
              };
            } else if (Number(interactionRate) < 80) {
              return {
                ...item,
                value: interactionRate + "%",
                status: "數據異常",
                result: interactionRate + "%",
              };
            }
          }
          return item;
        })
      );
    }
  }, [interactionRate]);
  useEffect(() => {
    if (conversionEvents !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkConversionEvents") {
            if (conversionEvents.length) {
              return {
                ...item,
                value: conversionEvents,
                status: "正常運作",
                result: conversionEvents,
              };
            } else {
              return {
                ...item,
                value: [],
                status: "建議修正",
                result: "未設定",
              };
            }
          }
          return item;
        })
      );
    }
  }, [conversionEvents]);
  useEffect(() => {
    if (audience !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkAudience") {
            if (audience.length) {
              return {
                ...item,
                value: audience,
                status: "正常運作",
                result: audience,
              };
            } else {
              return {
                ...item,
                value: [],
                status: "建議修正",
                result: "未設定",
              };
            }
          }
          return item;
        })
      );
    }
  }, [audience]);
  useEffect(() => {
    if (predicted !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkPredicted") {
            if (predicted.length) {
              return {
                ...item,
                value: predicted,
                status: "正常運作",
                result: predicted,
              };
            } else {
              return {
                ...item,
                value: [],
                status: "建議修正",
                result: "未啟用",
              };
            }
          }
          return item;
        })
      );
    }
  }, [predicted]);
  useEffect(() => {
    if (eccomerce !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkEccomerce") {
            if (eccomerce.filter((item) => Number(item.value) === 0).length) {
              return {
                ...item,
                value: eccomerce,
                status: "建議修正",
                result: eccomerce,
              };
            } else {
              return {
                ...item,
                value: eccomerce,
                status: "正常運作",
                result: eccomerce,
              };
            }
          }
          return item;
        })
      );
    }
  }, [eccomerce]);
  useEffect(() => {
    if (medium !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkMedium") {
            if (medium.length) {
              return {
                ...item,
                value: medium,
                status: "正常運作",
                result: medium,
              };
            } else {
              return {
                ...item,
                value: [],
                status: "建議修正",
                result: "未設定",
              };
            }
          }
          return item;
        })
      );
    }
  }, [medium]);
  useEffect(() => {
    if (userId !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkUserId") {
            if (userId) {
              return {
                ...item,
                value: userId,
                status: "正常運作",
                result: userId,
              };
            } else {
              return {
                ...item,
                value: false,
                status: "建議修正",
                result: "建議修正",
              };
            }
          }
          return item;
        })
      );
    }
  }, [userId]);
  useEffect(() => {
    if (
      webDataStreamsMeasurementProtocol !== null &&
      androidAppDataStreamsMeasurementProtocol !== null &&
      iosAppDataStreamsMeasurementProtocol !== null
    ) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkDataStreamsMeasurementProtocol") {
            if (
              webDataStreamsMeasurementProtocol.length ||
              androidAppDataStreamsMeasurementProtocol.length ||
              iosAppDataStreamsMeasurementProtocol.length
            ) {
              return {
                ...item,
                value: webDataStreamsMeasurementProtocol
                  .concat(androidAppDataStreamsMeasurementProtocol)
                  .concat(iosAppDataStreamsMeasurementProtocol),
                status: "正常運作",
                result: "有設定",
              };
            } else {
              return {
                ...item,
                value: [],
                status: "正常運作",
                result: "未申請",
              };
            }
          }
          return item;
        })
      );
    }
  }, [
    webDataStreamsMeasurementProtocol,
    androidAppDataStreamsMeasurementProtocol,
    iosAppDataStreamsMeasurementProtocol,
  ]);
  useEffect(() => {
    if (googleCPCTrans !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkGoogleCPCTrans") {
            if (googleCPCTrans) {
              return {
                ...item,
                value: googleCPCTrans,
                status: "正常運作",
                result: "有轉換",
              };
            } else {
              return {
                ...item,
                value: [],
                status: "正常運作",
                result: "無轉換",
              };
            }
          }
          return item;
        })
      );
    }
  }, [googleCPCTrans]);
  useEffect(() => {
    if (unassigned !== null) {
      setCheckListResult(
        checkListResult.map((item) => {
          if (item.type === "checkUnassigned") {
            if (unassigned === 0) {
              return {
                ...item,
                value: true,
                status: "正常運作",
                result: "",
              };
            } else {
              return {
                ...item,
                value: true,
                status: "建議修正",
                result: "",
              };
            }
          }
          return item;
        })
      );
    }
  }, [unassigned]);
  useEffect(() => {
    checkListResult.map((item, index) =>
      console.log(
        "====== ",
        index + 1 + ". " + item.type,
        item.status,
        item.result,
        item.value
      )
    );
  }, [checkListResult]);
  /* api result end */

  useEffect(() => {
    // 登入或登出時都先重置狀態
    resetTableData();
    setAccountSummaries([]);
    setAccount("");
    setPropertySummaries([]);
    setProperty("");
    setEchartsOption(getEchartsOption(0));
  }, [google_is_signed_in]);

  useEffect(() => {
    if (
      google_is_signed_in &&
      google_gapi_loaded &&
      !google_gapi_loaded_error &&
      google_gapi_analyticsadmin_loaded &&
      !google_gapi_analyticsadmin_loaded_error &&
      google_gapi_analyticsdata_loaded &&
      !google_gapi_analyticsdata_loaded_error
    ) {
      setAccountSummariesLoading(true);
      setIsModalVisible(false);

      window.gapi.client.analyticsadmin.accountSummaries
        .list({
          pageSize: 200,
        })
        .then(
          (response) => {
            // console.log(response.result)

            setAccountSummaries(
              response.result.accountSummaries
                ? response.result.accountSummaries
                    .filter((item) => item.account !== "accounts/54516992")
                    .filter((item) => item.propertySummaries)
                : []
            );
            setAccountSummariesLoading(false);
            setAccountSummariesLoaded(true);

            setPropertySummaries([]);
            setProperty("");
          },
          (err) => {
            console.error("Execute error", err);
          }
        );
    } else {
      setAccountSummaries([]);
      setAccountSummariesLoaded(false);
    }
  }, [
    google_is_signed_in,
    google_gapi_loaded,
    google_gapi_loaded_error,
    google_gapi_analyticsadmin_loaded,
    google_gapi_analyticsadmin_loaded_error,
    google_gapi_analyticsdata_loaded,
    google_gapi_analyticsdata_loaded_error,
  ]);

  useEffect(() => {
    if (account) {
      const propertySummaries = accountSummaries.find(
        (item) => item.account === account
      ).propertySummaries;

      if (propertySummaries) {
        setPropertySummaries(propertySummaries);
      } else {
        setPropertySummaries([]);
      }
    } else {
      setPropertySummaries([]);
    }
  }, [account]);

  useEffect(() => {
    if (account && property) {
      resetTableData();

      checkDataStreams();
      checkHostName();
      checkCrossDomain();
      checkProperty();
      checkGoogleSignalsSettings();
      checkDataRetentionSettings();
      checkGoogleAds();
      checkInteractionRate();
      checkConversionEvents();
      checkUserProperties();
      checkAudience();
      checkEccomerce();
      checkMedium();
      checkGoogleCPCTrans();
      checkUserId();
      checkUnassigned();
      checkConversionEventsDefault();

      // Promise.all([
      //   checkDataStreams(),
      //   // checkWebDataStreams(),
      //   // checkAndroidAppDataStreams(),
      //   // checkIosAppDataStreams(),
      //   checkHostName(),
      //   checkCrossDomain(),
      //   checkProperty(),
      //   checkGoogleSignalsSettings(),
      //   checkDataRetentionSettings(),
      //   checkGoogleAds(),
      //   checkInteractionRate(),
      //   checkConversionEvents(),
      //   checkUserProperties(),
      //   checkAudience(),
      //   checkEccomerce(),
      //   checkMedium(),
      //   // checkWebDataStreamsMeasurementProtocol(),
      //   // checkAndroidAppDataStreamsMeasurementProtocol(),
      //   // checkIosAppDataStreamsMeasurementProtocol(),
      //   checkGoogleCPCTrans(),
      //   checkUserId(),
      //   checkUnassigned(),
      // ]).then((values) => {
      //   // console.log("Promise.all = ", values)
      //   // setIsCheckApiFetchFinished(true)
      // });

      // checkWebDataStreams()
      // checkAndroidAppDataStreams()
      // checkIosAppDataStreams()
      // checkHostName()
      // checkCrossDomain()
      // checkProperty()
      // checkGoogleSignalsSettings()
      // checkDataRetentionSettings()
      // checkGoogleAds()
      // checkInteractionRate()
      // checkConversionEvents()
      // checkUserProperties()
      // checkAudience()
      // checkEccomerce()
      // checkMedium()
    }

    setIsCheckApiFetchFinished(false);
  }, [account, property]);

  if (device == "mobile") {
    submitBtnOffset = 0;
  } else {
    submitBtnOffset = 10;
    // submitBtnOffset = 3
  }

  // 設定多國語系
  const checkListResultI18n = checkListResult.map((item) => {
    // console.log("item = ", item);

    if (item.type === "checkProperty") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-1.name"),
        },
        description: t("check-result.item-1.info"),
      };
    }
    if (item.type === "checkDataStreams") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-2.name"),
        },
        description: t("check-result.item-2.info"),
      };
    }
    if (item.type === "checkHostName") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-3.name"),
        },
        description: t("check-result.item-3.info"),
      };
    }
    if (item.type === "checkCrossDomain") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-4.name"),
        },
        description: t("check-result.item-4.info"),
      };
    }
    if (item.type === "checkIndustryCategory") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-5.name"),
        },
        description: t("check-result.item-5.info"),
      };
    }
    if (item.type === "checkTimeZone") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-6.name"),
        },
        description: t("check-result.item-6.info"),
      };
    }
    if (item.type === "checkCurrencyCode") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-7.name"),
        },
        description: t("check-result.item-7.info"),
      };
    }
    if (item.type === "checkGoogleSignalsSettings") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-8.name"),
        },
        description: t("check-result.item-8.info"),
      };
    }
    if (item.type === "checkDataRetentionSettings") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-9.name"),
        },
        description: t("check-result.item-9.info"),
      };
    }
    if (item.type === "checkUserProperties") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-10.name"),
        },
        description: t("check-result.item-10.info"),
      };
    }
    if (item.type === "checkGoogleAdsLinks") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-11.name"),
        },
        description: t("check-result.item-11.info"),
      };
    }
    if (item.type === "checkInteractionRate") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-12.name"),
        },
        description: t("check-result.item-12.info"),
      };
    }
    if (item.type === "checkConversionEvents") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-13.name"),
        },
        description: t("check-result.item-13.info"),
      };
    }
    if (item.type === "checkAudience") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-14.name"),
        },
        description: t("check-result.item-14.info"),
      };
    }
    if (item.type === "checkPredicted") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-15.name"),
        },
        description: t("check-result.item-15.info"),
      };
    }
    if (item.type === "checkEccomerce") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-16.name"),
        },
        description: t("check-result.item-16.info"),
      };
    }
    if (item.type === "checkMedium") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-17.name"),
        },
        description: t("check-result.item-17.info"),
      };
    }
    if (item.type === "checkUserId") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-18.name"),
        },
        description: t("check-result.item-18.info"),
      };
    }
    if (item.type === "checkDataStreamsMeasurementProtocol") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-19.name"),
        },
        description: t("check-result.item-19.info"),
      };
    }
    if (item.type === "checkGoogleCPCTrans") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-20.name"),
        },
        description: t("check-result.item-20.info"),
      };
    }
    if (item.type === "checkUnassigned") {
      return {
        ...item,
        name: {
          ...item.name,
          tw: t("check-result.item-21.name"),
        },
        description: t("check-result.item-21.info"),
      };
    }

    return item;
  });

  // let status = t("check-result.status.init");
  // if (item.status == "正常運作") {
  //   status = t("check-result.status.normal");
  // } else if (item.status == "建議修正") {
  //   status = t("check-result.status.suggestion");
  // } else if (item.status == "數據異常") {
  //   status = t("check-result.status.error");
  // }
  // 基本設定
  let checkListResultBasic = checkListResultI18n.filter((item) => {
    if (
      item.type == "checkProperty" ||
      item.type == "checkCrossDomain" ||
      item.type == "checkIndustryCategory" ||
      item.type == "checkTimeZone" ||
      item.type == "checkCurrencyCode" ||
      item.type == "checkDataRetentionSettings" ||
      item.type == "checkHostName"
      // item.type == "checkDataStreamsMeasurementProtocol"
    ) {
      return item;
    }
  });
  let checkListResultBasicError = checkListResultBasic.filter((item) => {
    if (item.status == "數據異常") {
      return item;
    }
  });
  let checkListResultBasicNormal = checkListResultBasic.filter((item) => {
    if (item.status == "正常運作") {
      return item;
    }
  });
  let checkListResultBasicSuggestion = checkListResultBasic.filter((item) => {
    if (item.status == "建議修正") {
      return item;
    }
  });
  let checkListResultBasicInit = checkListResultBasic.filter((item) => {
    if (item.status == "尚未檢核") {
      return item;
    }
  });
  checkListResultBasic = [
    ...checkListResultBasicError,
    ...checkListResultBasicSuggestion,
    ...checkListResultBasicNormal,
    ...checkListResultBasicInit,
  ];
  // console.log("checkListResultBasic = ", checkListResultBasic)

  // 數據收集
  let checkListResultCollect = checkListResultI18n.filter((item) => {
    if (
      item.type == "checkDataStreams" ||
      item.type == "checkGoogleSignalsSettings" ||
      item.type == "checkGoogleAdsLinks" ||
      item.type == "checkEccomerce" ||
      item.type == "checkUserId" ||
      item.type == "checkGoogleCPCTrans" ||
      item.type == "checkUnassigned"
    ) {
      return item;
    }
  });
  let checkListResultCollectError = checkListResultCollect.filter((item) => {
    if (item.status == "數據異常") {
      return item;
    }
  });
  let checkListResultCollectNormal = checkListResultCollect.filter((item) => {
    if (item.status == "正常運作") {
      return item;
    }
  });
  let checkListResultCollectSuggestion = checkListResultCollect.filter(
    (item) => {
      if (item.status == "建議修正") {
        return item;
      }
    }
  );
  let checkListResultCollectInit = checkListResultCollect.filter((item) => {
    if (item.status == "尚未檢核") {
      return item;
    }
  });
  checkListResultCollect = [
    ...checkListResultCollectError,
    ...checkListResultCollectSuggestion,
    ...checkListResultCollectNormal,
    ...checkListResultCollectInit,
  ];
  // console.log("checkListResultCollect ===", checkListResultCollect)

  // 數據運用
  let checkListResultApplication = checkListResultI18n.filter((item) => {
    if (
      item.type == "checkUserProperties" ||
      item.type == "checkInteractionRate" ||
      item.type == "checkConversionEvents" ||
      item.type == "checkAudience" ||
      item.type == "checkPredicted" ||
      item.type == "checkMedium"
    ) {
      return item;
    }
  });
  let checkListResultApplicationError = checkListResultApplication.filter(
    (item) => {
      if (item.status == "數據異常") {
        return item;
      }
    }
  );
  let checkListResultApplicationNormal = checkListResultApplication.filter(
    (item) => {
      if (item.status == "正常運作") {
        return item;
      }
    }
  );
  let checkListResultApplicationSuggestion = checkListResultApplication.filter(
    (item) => {
      if (item.status == "建議修正") {
        return item;
      }
    }
  );
  let checkListResultApplicationInit = checkListResultApplication.filter(
    (item) => {
      if (item.status == "尚未檢核") {
        return item;
      }
    }
  );
  checkListResultApplication = [
    ...checkListResultApplicationError,
    ...checkListResultApplicationSuggestion,
    ...checkListResultApplicationNormal,
    ...checkListResultApplicationInit,
  ];

  // 所有 checkListResult 的狀態結果
  let checkListResultError = checkListResult.filter((item) => {
    if (item.status == "數據異常") {
      return item;
    }
  });
  // console.log("數據異常 = ", checkListResultError)
  let checkListResultNormal = checkListResult.filter((item) => {
    if (item.status == "正常運作") {
      return item;
    }
  });
  // console.log("正常運作 = ", checkListResultNormal)
  let checkListResultSuggestion = checkListResult.filter((item) => {
    if (item.status == "建議修正") {
      return item;
    }
  });
  // console.log("建議修正 = ", checkListResultSuggestion)

  const CollapseOnChange = (keys) => {
    // setCollapseKeys(key);

    // console.log("CollapseOnChange = ", keys);

    // 找出新開啟的 key
    const newlyOpenedKeys = keys.filter(
      (key) => !previousKeysRef.current.includes(key)
    );

    if (newlyOpenedKeys.length > 0) {
      // console.log("Newly opened:", newlyOpenedKeys);
      utilsIndex.sendGAEvent("engage", {
        turing_01: "觀看說明",
        turing_02: "Check Assistant頁",
      });
    }

    // 更新 previousKeysRef 的值
    previousKeysRef.current = keys;
  };

  // console.log('checkListResult ===', checkListResult)

  return (
    <div
      className={
        device == "mobile" ? "check-assistant mobile" : "check-assistant"
      }
    >
      {!docCookies.getItem("startModalCancel_Infinity") && (
        <div>
          <Modal
            title=""
            visible={isStarModalVisible}
            //onOk={starModalOk}
            onCancel={starModalCancel}
            className="check_modal"
            footer={[
              <Button onClick={starModalOk}>{t("check-modal.yes-btn")}</Button>,
              <Button onClick={starModalAnswerNo}>
                {t("check-modal.cancel-btn")}
              </Button>,
            ]}
          >
            <div className="Modal_head">
              <img
                src={iconOnModal}
                style={{ width: "100px", height: "auto" }}
              />
              <div className="Modal_head_text" style={{ fontSize: "16px" }}>
                Google Analytics
              </div>
            </div>
            <div className="title">{t("check-modal.content")}</div>
          </Modal>
        </div>
      )}

      <div>
        <Modal
          title=""
          visible={isTimesWaringModalVisible}
          onOk={timesWaringModalOk}
          onCancel={timesWaringModalCancel}
          className="check_times_modal"
          footer={[
            <Button onClick={timesWaringModalOk}>
              {t("check-times-modal.yes-btn")}
            </Button>,
            <Button onClick={timesWaringModalAnswerNo}>
              {t("check-times-modal.cancel-btn")}
            </Button>,
          ]}
        >
          <div className="Modal_head">
            <img src={timesCheck} style={{ width: "100px", height: "auto" }} />
            <div className="Modal_head_text" style={{ fontSize: "16px" }}>
              Waring
            </div>
          </div>
          <div className="title">{t("check-times-modal.content")}</div>
        </Modal>
      </div>

      {/* <div className="top-alert">
        <img src={imgWarn} style={{ width: "14px" }} />
        {t("top-alert")}
      </div> */}
      <div className="top section">
        <div className="logo">
          <a target="_blank" href="https://bit.ly/3C2hmlM">
            <img src={imgLogo} />
          </a>
        </div>
        <Dropdown.Button
          className="language"
          menu={menuProps}
          placement="bottom"
          icon={<GlobalOutlined />}
        >
          {language === "tw" ? "繁體中文" : "簡體中文"}
        </Dropdown.Button>
        <div className="banner">
          <img className="icon-main" src={imgIconMain} />
          {/* <img className="icon-beta" src={imgIconBeta} /> */}
        </div>
        <h1 className="name">
          {t("top.name-1")}
          <br /> {t("top.name-2")}
          <div className="name-intro">
            <img src={laurelLeft} />
            <div className="text">
              {t("top.name-intro-1")}
              <br />
              {t("top.name-intro-2")}
            </div>
            <img src={laurelReft} />
            {/* 22_05_04_小標修改
            找出調整與優化方向，
            <br />
            開啟數據分析之路 */}
          </div>
        </h1>
        {/* <div className="present">Presented By Turing Digital</div> */}
        <div className="gmpc">
          <img src={imgGMPC} />
        </div>
      </div>
      <div className="step section">
        <div className="experiment-wrap">
          <div className="experiment step">
            <div className="title">
              {t("slogan.main-1")}
              <span>、</span>
              <br />
              {t("slogan.main-2")}
              <span>、</span>
              <br />
              {t("slogan.main-3")}
            </div>
            <div>{t("slogan.sub")}</div>
          </div>
        </div>
        <div className={`account ${google_is_signed_in ? "align-left" : ""}`}>
          <Space
            direction={"vertical"}
            style={{
              width: "100%",
              marginTop: "20px",
              // textAlign: "left",
            }}
          >
            {google_is_signed_in ? (
              <Space
                direction={"horizontal"}
                style={{
                  width: "100%",
                  marginTop: "20px",
                  textAlign: "left",
                }}
              >
                <h3
                  style={{
                    fontSize: "1rem",
                    margin: "0",
                    fontWeight: "400",
                  }}
                >
                  Step 1. {t("step.step-1")}
                </h3>
                <span style={{ color: "rgb(127, 185, 0)", fontSize: "1rem" }}>
                  {t("step.success")}
                </span>
              </Space>
            ) : (
              ""
            )}
            <GoogleAuthButton />
            {/* <LoginSocialGoogle
              client_id="405430373772-gcumiucgm2059ck0acp691gfflnej3s3.apps.googleusercontent.com"
              onLoginStart={onLoginStart}
              // redirect_uri={REDIRECT_URI}
              scope="https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.email"
              // discoveryDocs="claims_supported"
              access_type="offline"
              onResolve={({ provider, data }) => {
                console.log("🚀 ~ CheckAssistant ~ provider:", provider);
                console.log("🚀 ~ CheckAssistant ~ data:", data);
                setProvider(provider);
                setProfile(data);
              }}
              onReject={(err) => {
                console.log(err);
              }}
            >
              <GoogleLoginButton />
            </LoginSocialGoogle> */}
            {/* <Modal
              title="使用說明"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              <p style={{ fontSize: "18px" }}>
                由於系統目前還在 Beta
                測試版本，假如短時間有大量用戶使用，在登入過程中會跳出安全性驗證的視窗，無需擔心，請依照以下步驟完成登入，感謝！
              </p>
              <img src={imgLoginAuth} />
              <div className="start-wrap">
                <hr style={{ marginBottom: "20px" }} />
              </div>
            </Modal> */}
          </Space>
          {google_is_signed_in && (
            <div className="account-source">
              {accountSummariesLoaded & (accountSummaries.length == 0) ? (
                <div className="no-ga4">此帳號無 GA4 資料</div>
              ) : (
                <div>
                  <Space
                    direction={"vertical"}
                    style={{
                      width: "100%",
                      marginTop: "18px",
                      textAlign: "left",
                    }}
                  >
                    <h3
                      style={{
                        fontSize: "1rem",
                        margin: "0",
                        fontWeight: "400",
                      }}
                    >
                      Step 2. {t("step.step-2")}
                    </h3>
                    <Select
                      defaultValue=""
                      style={{ width: "100%" }}
                      allowClear
                      loading={accountSummariesLoading}
                      onChange={(value) => {
                        setAccount(value);
                        setProperty("");
                        resetTableData();
                        utilsIndex.sendGAEvent("engage", {
                          turing_01: "選擇帳戶",
                          turing_02: "Check Assistant頁",
                        });
                      }}
                      showSearch
                      optionFilterProp="children"
                      onSearch={onSearch}
                      filterOption={(input, option) => {
                        return (
                          option.children.props.children[0].props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {accountSummaries.map((item) => {
                        return (
                          <Option
                            key={item.account}
                            label={item.displayName}
                            value={item.account}
                          >
                            <Space direction={"vertical"}>
                              <Typography.Text strong>
                                {item.displayName}
                              </Typography.Text>
                              <Typography.Text type={"secondary"}>
                                {item.account}
                              </Typography.Text>
                            </Space>
                          </Option>
                        );
                      })}
                    </Select>
                  </Space>
                  <Space
                    direction={"vertical"}
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      textAlign: "left",
                    }}
                  >
                    <Space direction={"horizontal"}>
                      <h3
                        style={{
                          fontSize: "1rem",
                          margin: "0",
                          fontWeight: "400",
                        }}
                      >
                        Step 3. {t("step.step-3")}
                      </h3>
                      {googleApiFalse ? (
                        <span
                          style={{
                            color: "#f90000",
                            fontSize: "1rem",
                          }}
                        >
                          Opps！Google 的系統似乎有點故障，請晚點再來嘗試
                        </span>
                      ) : property && !score ? (
                        <span
                          style={{
                            color: "rgb(127, 185, 0)",
                            fontSize: "1rem",
                          }}
                        >
                          選擇後會自動執行，請耐心等候
                        </span>
                      ) : property && score ? (
                        <span
                          style={{
                            color: "rgb(127, 185, 0)",
                            fontSize: "1rem",
                          }}
                        >
                          執行完畢
                        </span>
                      ) : (
                        ""
                      )}
                    </Space>

                    <Select
                      defaultValue=""
                      value={property}
                      style={{ width: "100%" }}
                      allowClear
                      onChange={(value) => {
                        // console.log("outOfQuota = ", outOfQuota);
                        utilsIndex.sendGAEvent("choose_property");
                        utilsIndex.sendGAEvent("engage", {
                          turing_01: "選擇GA4 ID",
                          turing_02: "Check Assistant頁",
                        });

                        if (outOfQuota == "true") {
                          utilsIndex.sendGAEvent("used_10_times");
                          setIsTimesWaringModalVisible(true);
                        } else {
                          setProperty(value);
                        }
                      }}
                      showSearch
                      optionFilterProp="children"
                      onSearch={onSearch}
                      filterOption={(input, option) => {
                        return (
                          option.children.props.children[0].props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {propertySummaries.map((item) => {
                        return (
                          <Option
                            key={item.property}
                            label={item.displayName}
                            value={item.property}
                          >
                            <Space direction={"vertical"}>
                              <Typography.Text strong>
                                {item.displayName}
                              </Typography.Text>
                              <Typography.Text type={"secondary"}>
                                {item.property}
                              </Typography.Text>
                            </Space>
                          </Option>
                        );
                      })}
                    </Select>
                    {/* <Typography.Text
                  type={"secondary"}
                  style={{ marginLeft: "12px" }}
                >
                  {property}
                </Typography.Text> */}
                  </Space>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="score section">
        <ReactECharts className="score-chart" option={echartsOption} />
        {/* <ScoreChart /> */}
        {prScore > 0 && (
          <div>
            <div className="score-result">
              檢核結果 {score} 分，落後 {100 - prScore}% 的網站
            </div>
            <div className="fix-quation">
              <Link
                activeClass="active"
                className="test1"
                to="test1"
                spy={true}
                smooth={true}
                duration={500}
                onClick={handleFixQuation}
              >
                <div
                  style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "54px",
                    borderRadius: "4px",
                    padding: "8px 12px 8px 3px",
                    backgroundColor: "#4384f3",
                  }}
                >
                  <span
                    style={{
                      display: "inline-flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: "48px",
                      minWidth: "48px",
                      borderRadius: "4px",
                      backgroundColor: "white",
                    }}
                  >
                    <img style={{ width: "36px" }} src={imgIconFix} />
                  </span>

                  <span
                    style={{
                      display: "inline-flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "12px",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    立刻修正問題
                  </span>
                </div>
              </Link>
            </div>
          </div>
        )}
        <div className="score-date">
          {t("check-date")}:{" "}
          {moment().subtract(31, "days").format("YYYY/MM/DD")} -{" "}
          {moment().subtract(1, "days").format("YYYY/MM/DD")}
        </div>
        <div className="score-interval">
          <div className="score-error interval-item">
            <img src={statusError} />
            <div className="interval-text">0-49</div>
          </div>
          <div className="score-fix interval-item">
            <img src={statusFix} />
            <div className="interval-text">50-89</div>
          </div>
          <div className="score-normal interval-item">
            <img src={statusNormal} />
            <div className="interval-text">90-100</div>
          </div>
        </div>
      </div>
      {prScore > 0 && (
        <div className="status-sum">
          <Row>
            <Col xs={24} sm={8}>
              <div className="status-sum-error">
                數據異常: {checkListResultError.length}
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="status-sum-suggestion">
                建議修正: {checkListResultSuggestion.length}
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="status-sum-normal">
                正常運作: {checkListResultNormal.length + 2}
              </div>
            </Col>
          </Row>
        </div>
      )}
      <div className="show-table section">
        {google_is_signed_in && (
          <>
            <p className="table-title">
              {/* 基本設定 */}
              {t("check-result.basic.title")}
            </p>
            <Collapse
              defaultActiveKey={[]}
              expandIconPosition="right"
              onChange={CollapseOnChange}
            >
              {checkListResultBasic.map((item, index) => {
                // console.log(index + ". item = ", item)
                const result = getCheckListItemResult(item);
                const status = getCheckListItemStatus(item);

                return (
                  <Panel
                    key={item.key}
                    header={
                      <>
                        {/* <span className="checklist-idx">{index + 1}</span> */}
                        <span className="checklist-status">{status}</span>
                        {/* {getStatusIcon(item.status)}
                      <span className="checklist-status" style={{ color: color, marginRight: "4px" }}>{item.status}</span> */}
                        <span className="checklist-name">{item.name.tw}</span>
                        <Tag>{item.name.en}</Tag>
                      </>
                    }
                  >
                    <p>
                      {item.description}
                      {item.descriptionLink && (
                        <a target="_blank" href={item.descriptionLink}>
                          {/* 瞭解詳情 */}
                          {t("check-result.more")}
                        </a>
                      )}
                    </p>
                    <div className="checklist-result">
                      <div className="checklist-result-title">
                        {/* 檢測結果 */}
                        {t("check-result.result")}
                      </div>
                      <div className="checklist-result-content">{result}</div>
                    </div>
                  </Panel>
                );
              })}
            </Collapse>

            <p className="table-title">
              {/* 數據收集 */}
              {t("check-result.collect.title")}
            </p>
            <Collapse defaultActiveKey={[]} expandIconPosition="right">
              {checkListResultCollect.map((item, index) => {
                const result = getCheckListItemResult(item);
                const status = getCheckListItemStatus(item);

                return (
                  <Panel
                    key={item.key}
                    header={
                      <>
                        {/* <span className="checklist-idx">{index + 1}</span> */}
                        <span className="checklist-status">{status}</span>
                        {/* {getStatusIcon(item.status)}
                      <span className="checklist-status" style={{ color: color, marginRight: "4px" }}>{item.status}</span> */}
                        <span className="checklist-name">{item.name.tw}</span>
                        <Tag>{item.name.en}</Tag>
                      </>
                    }
                  >
                    <p>
                      {item.description}
                      {item.descriptionLink && (
                        <a target="_blank" href={item.descriptionLink}>
                          {/* 瞭解詳情 */}
                          {t("check-result.more")}
                        </a>
                      )}
                    </p>
                    <div className="checklist-result">
                      <div className="checklist-result-title">
                        {/* 檢測結果 */}
                        {t("check-result.result")}
                      </div>
                      <div className="checklist-result-content">{result}</div>
                    </div>
                  </Panel>
                );
              })}
            </Collapse>

            <p className="table-title">
              {/* 數據運用 */}
              {t("check-result.application.title")}
            </p>
            <Collapse defaultActiveKey={[]} expandIconPosition="right">
              {checkListResultApplication.map((item, index) => {
                const result = getCheckListItemResult(item);
                const status = getCheckListItemStatus(item);

                return (
                  <Panel
                    key={item.key}
                    header={
                      <>
                        {/* <span className="checklist-idx">{index + 1}</span> */}
                        <span className="checklist-status">{status}</span>
                        {/* {getStatusIcon(item.status)}
                      <span className="checklist-status" style={{ color: color, marginRight: "4px" }}>{item.status}</span> */}
                        <span className="checklist-name">{item.name.tw}</span>
                        <Tag>{item.name.en}</Tag>
                      </>
                    }
                  >
                    <p>
                      {item.description}
                      {item.descriptionLink && (
                        <a target="_blank" href={item.descriptionLink}>
                          {/* 瞭解詳情 */}
                          {t("check-result.more")}
                        </a>
                      )}
                    </p>
                    <div className="checklist-result">
                      <div className="checklist-result-title">
                        {/* 檢測結果 */}
                        {t("check-result.result")}
                      </div>
                      <div className="checklist-result-content">{result}</div>
                    </div>
                  </Panel>
                );
              })}
            </Collapse>
          </>
        )}
      </div>
      {prScore > 0 && (
        <div className="contact-form">
          <Element name="test1" className="element"></Element>
          <div className="contact-text">
            <p className="title">{t("contact-form.title")}</p>
            <p className="sub-title">{t("contact-form.sub")}</p>
          </div>
          <Form
            {...formLayout}
            name="nest-messages"
            onFinish={handleSubmitContactForm}
            validateMessages={validateMessages}
            initialValues={{
              contact: {
                time: [
                  "平日 09:30 - 12:00",
                  "平日 13:30 - 15:30",
                  "平日 15:30 - 18:30",
                ],
              },
            }}
          >
            <Form.Item
              name={["contact", "name"]}
              label={t("contact-form.name")}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={["contact", "phone"]}
              label={t("contact-form.phone")}
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^(09)[0-9]{8}$/,
                  message: "請輸入正確的手機",
                },
              ]}
            >
              <Input style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name={["contact", "email"]}
              label={t("contact-form.email")}
              rules={[
                {
                  required: true,
                  type: "email",
                },
                {
                  message: "請輸入正確的手機",
                },
              ]}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item name={["contact", "items"]} label="諮詢項目">
              {checkListResult.map((item) => {
                let color = ""
                if (item.status == "數據異常") {
                  color = "#f90000"
                } else if (item.status == "建議修正") {
                  color = "#fdb900"
                }

                if (item.status == "數據異常") {
                  return (
                    <div key={item.key}>
                      <CloseCircleFilled
                        style={{ color: color, marginRight: "8px" }}
                      />
                      <span style={{ color: color }}>{item.status}</span>{" "}
                      {item.name.tw}
                    </div>
                  )
                }
              })}
              {checkListResult.map((item) => {
                // console.log("xxxxxx item = ", item)
                let color = ""
                if (item.status == "數據異常") {
                  color = "#f90000"
                } else if (item.status == "建議修正") {
                  color = "#fdb900"
                }

                if (item.status == "建議修正") {
                  return (
                    <div key={item.key}>
                      <WarningFilled
                        style={{ color: color, marginRight: "8px" }}
                      />
                      <span style={{ color: color }}>{item.status}</span>{" "}
                      {item.name.tw}
                    </div>
                  )
                }
              })}
            </Form.Item> */}
            <Form.Item
              name={["contact", "others"]}
              label={t("contact-form.other")}
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              wrapperCol={{ ...formLayout.wrapperCol, offset: submitBtnOffset }}
            >
              {/* <Form.Item> */}
              <Button
                type="primary"
                htmlType="submit"
                disabled={isSubmitContactForm}
                style={{
                  display: "inline-flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "54px",
                  borderRadius: "4px",
                  padding: "8px 12px 8px 3px",
                  backgroundColor: "#4384f3",
                }}
              >
                <span
                  style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "48px",
                    minWidth: "48px",
                    borderRadius: "4px",
                    backgroundColor: "white",
                  }}
                >
                  <img style={{ width: "36px" }} src={imgIconArrow} />
                </span>

                <span
                  style={{
                    display: "inline-flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: "12px",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  {t("contact-form.submit")}
                </span>
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}

      <div className="service-1">
        <Row>
          <div
            style={{
              padding: "0 1rem",
            }}
          >
            <h2 className="title font-weight">{t("success-case.title")}</h2>
            <div className="sub-title">{t("success-case.sub")}</div>
          </div>
        </Row>
      </div>
      <div className="customer-experience">
        <div className="wrap">
          <div className="title font-weight">{t("user-experience.title")}</div>
          <Swiper
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            navigation
            pagination={{ clickable: true }}
            // autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            <SwiperSlide>
              <Row>
                <Col xs={24} sm={12}>
                  {/* <div style={{ marginBottom: "10px" }}>
              {device == "mobile" ? (
                <img src={imgService01} />
              ) : (
                <img src={imgService01PC} />
              )}
            </div> */}
                  <div
                    style={{
                      padding: "0 1rem",
                    }}
                  >
                    {t("user-experience.user1.text1")}
                    <div className="customer">
                      {t("user-experience.user1.text2")}
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div
                    className={
                      device == "mobile"
                        ? "customer-result mobile"
                        : "customer-result"
                    }
                  >
                    <div>{t("user-experience.user1.text3")}</div>
                    <div className="customer-result-num">
                      {t("user-experience.user1.text4")}
                    </div>
                  </div>
                </Col>
              </Row>
            </SwiperSlide>
            <SwiperSlide>
              <Row>
                <Col xs={24} sm={12}>
                  {/* <div style={{ marginBottom: "10px" }}>
              {device == "mobile" ? (
                <img src={imgService01} />
              ) : (
                <img src={imgService01PC} />
              )}
            </div> */}
                  <div
                    style={{
                      padding: "0 1rem",
                    }}
                  >
                    {t("user-experience.user2.text1")}
                    <div className="customer">
                      {t("user-experience.user2.text2")}
                    </div>
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div
                    className={
                      device == "mobile"
                        ? "customer-result mobile"
                        : "customer-result"
                    }
                  >
                    <div>{t("user-experience.user2.text3")}</div>
                    <div className="customer-result-num">
                      {t("user-experience.user2.text4")}
                    </div>
                  </div>
                </Col>
              </Row>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <div className="service-2">
        <div className="wrap">
          <div className="title">導入三個階段，協助您循序漸進的完成</div>
          {/* <Steps current={3}>
            <Step title="Finished" description="This is a description." />
            <Step
              title="In Progress"
              subTitle="Left 00:00:08"
              description="This is a description."
            />
            <Step title="Waiting" description="This is a description." />
          </Steps> */}
          <div className="wrapper option-1 option-1-1">
            <ol className="c-stepper">
              <li className="c-stepper__item">
                <h3 className="c-stepper__title">導入設定</h3>
                <p className="c-stepper__desc">- 確認帳戶結構</p>
                <p className="c-stepper__desc">- 創建分析資源</p>
                <p className="c-stepper__desc">- 串接 GA4 與 GTM</p>
                <p className="c-stepper__desc">- 完成 GA4 基本設定</p>
                <p className="c-stepper__desc">- 啟用 Google 信號</p>
                <p className="c-stepper__desc">- 串接 Google Ads</p>
              </li>
              <li className="c-stepper__item">
                <h3 className="c-stepper__title">學習使用</h3>
                <p className="c-stepper__desc">- 埋設與轉移自訂事件</p>
                <p className="c-stepper__desc">- 建立 User- ID</p>
                <p className="c-stepper__desc">- 建立事件轉換</p>
                <p className="c-stepper__desc">- 目標對象匯入 Google Ads</p>
                <p className="c-stepper__desc">- 導入加強型電子商務</p>
                <p className="c-stepper__desc">- 比較 Web 與 App 數據</p>
              </li>
              <li className="c-stepper__item">
                <h3 className="c-stepper__title">上手應用</h3>
                <p className="c-stepper__desc">- 學習解讀 GA4 報表</p>
                <p className="c-stepper__desc">- 利用數據優化 Web 或 App</p>
                <p className="c-stepper__desc">- 啟用預測目標對象功能</p>
                <p className="c-stepper__desc">- 利用廣告報表分析轉換歸因</p>
                <p className="c-stepper__desc">- 利用探索中心自訂報表</p>
                <p className="c-stepper__desc">
                  - Measurement Protocol 導入資料
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>

      {google_is_signed_in && (
        <div className="service-3">
          <div className="wrap">
            <h2 className="title">{t("service.title")}</h2>
            <Row>
              <Col xs={24} sm={12} md={6}>
                <div className="plan">
                  <div className="img">
                    <img src={imgService03Plan01} />
                  </div>
                  <h3 className="name">A. {t("service.item-1.name")}</h3>
                  <div className="descript">{t("service.item-1.descript")}</div>
                  <a
                    target="_blank"
                    href="https://www.turingdigital.com.tw/ga4-promotion-plan"
                    className="try"
                    onClick={() => {
                      utilsIndex.sendGAEvent("reserve_service", {
                        turing_01: "A. 推廣試用",
                        turing_02: "Check Assistant頁",
                      });
                    }}
                  >
                    {t("service.btn")}
                  </a>
                </div>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <div className="plan">
                  <div className="img">
                    <img src={imgService03Plan02} />
                  </div>
                  <h3 className="name">B. {t("service.item-2.name")}</h3>
                  <div className="descript">{t("service.item-2.descript")}</div>
                  <a
                    target="_blank"
                    href="https://www.turingdigital.com.tw/ga4-promotion-plan"
                    className="try"
                    onClick={() => {
                      utilsIndex.sendGAEvent("reserve_service", {
                        turing_01: "B. 基礎導入",
                        turing_02: "Check Assistant頁",
                      });
                    }}
                  >
                    {t("service.btn")}
                  </a>
                </div>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <div className="plan">
                  <div className="img">
                    <img src={imgService03Plan03} />
                  </div>
                  <h3 className="name">C. {t("service.item-3.name")}</h3>
                  <div className="descript">{t("service.item-3.descript")}</div>
                  <a
                    target="_blank"
                    href="https://www.turingdigital.com.tw/ga4-promotion-plan"
                    className="try"
                    onClick={() => {
                      utilsIndex.sendGAEvent("reserve_service", {
                        turing_01: "C. 完整分析",
                        turing_02: "Check Assistant頁",
                      });
                    }}
                  >
                    {t("service.btn")}
                  </a>
                </div>
              </Col>
              <Col xs={24} sm={12} md={6}>
                <div className="plan">
                  <div className="img">
                    <img src={imgService03Plan04} />
                  </div>
                  <h3 className="name">D. {t("service.item-4.name")}</h3>
                  <div className="descript">{t("service.item-4.descript")}</div>
                  <a
                    target="_blank"
                    href="https://www.turingdigital.com.tw/ga4-promotion-plan"
                    className="try"
                    onClick={() => {
                      utilsIndex.sendGAEvent("reserve_service", {
                        turing_01: "D. 進階應用",
                        turing_02: "Check Assistant頁",
                      });
                    }}
                  >
                    {t("service.btn")}
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}

      <div className="footer section">
        <h2 className="title">{t("notice.title")}</h2>
        <div className="content">
          <div />
          <p
            dangerouslySetInnerHTML={{
              __html: t("notice.item-1"),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: t("notice.item-2"),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: t("notice.item-3"),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: t("notice.item-4"),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: t("notice.item-5"),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: t("notice.item-6"),
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: t("notice.item-7"),
            }}
          />
        </div>
        <div className="other">
          <img src={imgFooter} />
        </div>
        <div className="copyright">{t("copyright")}</div>
      </div>
      <div className="contact">
        <MessengerChat
          pageId="1807982896101510"
          language="zh_TW"
          // themeColor={"#F2F3G2"}
          // height={24}
          // loggedInGreeting="Hello logged in user!"
          // loggedOutGreeting="Hello stranger!"
          // autoExpand={true}
          debugMode={false}
          onMessengerShow={() => {
            console.log("onMessengerShow");
          }}
          onMessengerHide={() => {
            console.log("onMessengerHide");
          }}
          onMessengerDialogShow={() => {
            console.log("onMessengerDialogShow");
          }}
          onMessengerDialogHide={() => {
            console.log("onMessengerDialogHide");
          }}
        />
        {/* <a
          target="_blank"
          href="https://www.turingdigital.com.tw/ga4-check-reservation"
        >
          <img src={imgContact} />
        </a> */}
      </div>
    </div>
  );
};

export default CheckAssistant;
