import React, { createContext, useContext, useEffect, useReducer } from "react";
// import { useHistory } from "react-router-dom";

// const API_KEY = "AIzaSyC6W7IQ-ouKtkNMsEP8W7Y2U5w7RRna_Go"
// const CLIENT_ID =
//   "900467741612-o97e7iod1umt0434gfrv9dstl3dfmvgo.apps.googleusercontent.com"
// const SCOPES =
//   "https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.email"

// const initConfig = {
//   apiKey: API_KEY,
//   client_id: CLIENT_ID,
//   scope: SCOPES,
//   access_type: "offline",
// }

const UPDATE_GOOGLE_GAPI_LOADED = "UPDATE_GOOGLE_GAPI_LOADED";
const UPDATE_GOOGLE_GAPI_LOADED_REEOR = "UPDATE_GOOGLE_GAPI_LOADED_REEOR";

const UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED =
  "UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED";
const UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED_ERROR =
  "UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED_ERROR";

const UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED =
  "UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED";
const UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED_ERROR =
  "UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED_ERROR";

const UPDATE_GOOGLE_IS_SIGNED_IN = "UPDATE_GOOGLE_IS_SIGNED_IN";
const UPDATE_GOOGLE_CURRENT_USER = "UPDATE_GOOGLE_CURRENT_USER";
const UPDATE_GOOGLE_GRANT_OFFLINE_ACCESS = "UPDATE_GOOGLE_GRANT_OFFLINE_ACCESS";

const loadScript = () => {
  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/api.js";
  script.async = true;
  script.defer = true;
  document.head.appendChild(script);
};
loadScript();

const GoogleStoreDispatchContext = createContext();
const GoogleStoreStateContext = createContext();

export const GoogleStoreProvider = ({ children }) => {
  // const history = useHistory();
  const { dispatch, state } = useGoogleStoreProvider();

  useEffect(() => {
    if (
      state.google_access_token &&
      state.google_expires_at &&
      state.google_offline_access &&
      state.google_user_email
    ) {
      console.log("google_access_token = ", state.google_access_token);
      console.log("google_expires_at = ", state.google_expires_at);
      console.log("google_offline_access = ", state.google_offline_access);
      console.log("google_user_email = ", state.google_user_email);

      // 本地測試不用送 api
      if (location.hostname !== "localhost") {
        fetch(`https://freeanalysisapi.turingdigital.com.tw/users/login`, {
          method: "POST",
          body: JSON.stringify({
            email: state.google_user_email,
            access_token: state.google_access_token,
            expires_at: state.google_expires_at,
            refresh_token: state.google_offline_access,
          }),
          headers: new Headers({
            "Content-Type": "application/json",
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            console.log(data);
          })
          .catch((e) => {
            console.error("Error:", e);
          });
      }
    }
  }, [
    state.google_access_token,
    state.google_expires_at,
    state.google_offline_access,
    state.google_user_email,
  ]);

  return (
    <GoogleStoreDispatchContext.Provider value={dispatch}>
      <GoogleStoreStateContext.Provider value={state}>
        {children}
      </GoogleStoreStateContext.Provider>
    </GoogleStoreDispatchContext.Provider>
  );
};

export const useGoogleStoreProvider = () => {
  const initState = {
    google_gapi_loaded: false,
    google_gapi_loaded_error: "",

    google_gapi_analyticsadmin_loaded: false,
    google_gapi_analyticsadmin_loaded_error: "",

    google_gapi_analyticsdata_loaded: false,
    google_gapi_analyticsdata_loaded_error: "",

    google_is_signed_in: false,

    google_access_token: null,
    google_expires_at: null,

    google_offline_access: null,

    google_user_email: null,
    google_user_image: null,

    // bigquery_event_number: 60,
  };

  const reducer = (state, action) => {
    // console.log("🚀 ~ reducer ~ action:", action);
    switch (action.type) {
      case UPDATE_GOOGLE_GAPI_LOADED: {
        return {
          ...state,
          google_gapi_loaded: action.payload,
        };
      }
      case UPDATE_GOOGLE_GAPI_LOADED_REEOR: {
        return {
          ...state,
          google_gapi_loaded_error: action.payload,
        };
      }
      case UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED: {
        return {
          ...state,
          google_gapi_analyticsadmin_loaded: action.payload,
        };
      }
      case UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED_ERROR: {
        return {
          ...state,
          google_gapi_analyticsadmin_loaded_error: action.payload,
        };
      }
      case UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED: {
        return {
          ...state,
          google_gapi_analyticsdata_loaded: action.payload,
        };
      }
      case UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED_ERROR: {
        return {
          ...state,
          google_gapi_analyticsdata_loaded_error: action.payload,
        };
      }
      case UPDATE_GOOGLE_IS_SIGNED_IN: {
        // console.log("UPDATE_GOOGLE_IS_SIGNED_IN = ", action.payload);
        return {
          ...state,
          google_is_signed_in: action.payload,
        };
      }
      case UPDATE_GOOGLE_CURRENT_USER: {
        // console.log("UPDATE_GOOGLE_CURRENT_USER = ", action.payload);
        return {
          ...state,
          ...action.payload,
        };
      }
      case UPDATE_GOOGLE_GRANT_OFFLINE_ACCESS: {
        return {
          ...state,
          google_offline_access: action.payload,
        };
      }
      default: {
        return {
          ...state,
        };
      }
    }
  };

  const [state, dispatch] = useReducer(reducer, initState);

  // gapi -> onload
  useEffect(() => {
    async function fetchGapiOnloadData() {
      // console.log("🚀 ~ fetchGapiOnloadData");
      const initConfig = await fetch(
        "https://freeanalysisapi.turingdigital.com.tw/token.json"
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          return {
            apiKey: data.API_KEY,
            client_id: data.Client_ID,
            scope:
              "https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.email",
            access_type: "offline",
          };
        });

      const frame = () => {
        if (window.gapi) {
          window.gapi.load("client:auth2", () => {
            // gapi -> init
            window.gapi.client.init(initConfig).then(
              () => {
                dispatch({
                  type: UPDATE_GOOGLE_GAPI_LOADED,
                  payload: true,
                });
                dispatch({
                  type: UPDATE_GOOGLE_GAPI_LOADED_REEOR,
                  payload: "",
                });
              },
              (err) => {
                dispatch({
                  type: UPDATE_GOOGLE_GAPI_LOADED,
                  payload: true,
                });
                dispatch({
                  type: UPDATE_GOOGLE_GAPI_LOADED_REEOR,
                  payload: JSON.stringify(err, null, 2),
                });
              }
            );
            // gapi -> admin api
            window.gapi.client
              .load(
                "https://analyticsadmin.googleapis.com/$discovery/rest?version=v1alpha"
              )
              .then(
                () => {
                  dispatch({
                    type: UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED,
                    payload: true,
                  });
                  dispatch({
                    type: UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED_ERROR,
                    payload: "",
                  });
                },
                (err) => {
                  dispatch({
                    type: UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED,
                    payload: true,
                  });
                  dispatch({
                    type: UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED_ERROR,
                    payload: JSON.stringify(err, null, 2),
                  });
                }
              );
            // gapi -> data api
            window.gapi.client
              .load(
                "https://analyticsdata.googleapis.com/$discovery/rest?version=v1beta"
              )
              .then(
                () => {
                  dispatch({
                    type: UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED,
                    payload: true,
                  });
                  dispatch({
                    type: UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED_ERROR,
                    payload: "",
                  });
                },
                (err) => {
                  dispatch({
                    type: UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED,
                    payload: true,
                  });
                  dispatch({
                    type: UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED_ERROR,
                    payload: JSON.stringify(err, null, 2),
                  });
                }
              );
            // gapi -> check sign in
            window.gapi.auth2.getAuthInstance().isSignedIn.listen((value) => {
              console.log("UPDATE_GOOGLE_IS_SIGNED_IN");
              dispatch({
                type: UPDATE_GOOGLE_IS_SIGNED_IN,
                payload: value,
              });
            });
          });
        } else {
          requestAnimationFrame(frame);
        }
      };
      const req = requestAnimationFrame(frame);

      return () => {
        cancelAnimationFrame(req);
      };
    }

    fetchGapiOnloadData();
  }, []);

  // gapi -> get user info
  useEffect(() => {
    if (state.google_is_signed_in) {
      const user = window.gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getBasicProfile();

      const auth = window.gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .getAuthResponse();

      dispatch({
        type: UPDATE_GOOGLE_CURRENT_USER,
        payload: {
          google_access_token: auth.access_token,
          google_expires_at: auth.expires_at,
          google_user_email: user.getEmail(),
          google_user_name: user.getGivenName(),
          google_user_image: user.getImageUrl(),
        },
      });
    } else {
      dispatch({
        type: UPDATE_GOOGLE_CURRENT_USER,
        payload: {
          google_access_token: null,
          google_expires_at: null,
          google_user_email: null,
          google_user_name: null,
          google_user_image: null,
        },
      });
    }
  }, [state.google_is_signed_in]);

  return {
    dispatch,
    state,
  };
};

export const useGoogleStoreAction = () => {
  const dispatch = useGoogleStoreDispatch();

  const googleAdminAPI = () => {
    dispatch({
      type: UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED,
      payload: false,
    });
    window.gapi.client
      .load(
        "https://analyticsadmin.googleapis.com/$discovery/rest?version=v1alpha"
      )
      .then(
        () => {
          dispatch({
            type: UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED,
            payload: true,
          });
          dispatch({
            type: UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED_ERROR,
            payload: "",
          });
        },
        (err) => {
          dispatch({
            type: UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED,
            payload: true,
          });
          dispatch({
            type: UPDATE_GOOGLE_GAPI_ANALYTICSADMIN_LOADED_ERROR,
            payload: JSON.stringify(err, null, 2),
          });
        }
      );
  };

  const googleDataAPI = () => {
    dispatch({
      type: UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED,
      payload: false,
    });
    window.gapi.client
      .load(
        "https://analyticsdata.googleapis.com/$discovery/rest?version=v1beta"
      )
      .then(
        () => {
          dispatch({
            type: UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED,
            payload: true,
          });
          dispatch({
            type: UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED_ERROR,
            payload: "",
          });
        },
        (err) => {
          dispatch({
            type: UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED,
            payload: true,
          });
          dispatch({
            type: UPDATE_GOOGLE_GAPI_ANALYTICSDATA_LOADED_ERROR,
            payload: JSON.stringify(err, null, 2),
          });
        }
      );
  };

  const googleSignIn = (callback) => {
    console.log("googleSignIn");
    window.gapi.auth2.getAuthInstance().signIn({ prompt: "consent" });

    if (typeof callback === "function") {
      googleGrantOfflineAccess(callback);
    } else {
      googleGrantOfflineAccess();
    }
  };

  const googleSignOut = () => {
    window.gapi.auth2.getAuthInstance().signOut();
  };

  const googleGrantOfflineAccess = (callback) => {
    console.log("googleGrantOfflineAccess");
    window.gapi.auth2
      .getAuthInstance()
      .grantOfflineAccess({ prompt: "consent" })
      .then((response) => {
        dispatch({
          type: UPDATE_GOOGLE_GRANT_OFFLINE_ACCESS,
          payload: response.code,
        });

        console.log("response.code = ", response.code);
        if (typeof callback === "function") {
          callback();
        }
      });
  };

  return {
    googleAdminAPI,
    googleDataAPI,
    googleSignIn,
    googleSignOut,
    googleGrantOfflineAccess,
  };
};

export const useGoogleStoreDispatch = () => {
  return useContext(GoogleStoreDispatchContext);
};

export const useGoogleStoreSelector = (callback) => {
  const state = useContext(GoogleStoreStateContext);

  return callback(state);
};
