export default [
  {
    key: "1",
    name: { tw: "追蹤代碼編號", en: "Property ID" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkProperty",
    value: "",
    description: "您的 Google Analytics 4 的資源編號。",
    descriptionLink:
      "https://support.google.com/analytics/answer/9304153?hl=zh-Hant&ref_topic=9303319",
  },
  {
    key: "2",
    name: { tw: "資料串流數量", en: "Data Streams" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkDataStreams",
    value: [],
    description:
      "從您的網站或應用程式流向 Google Analytics 4 的資料流，分為 3 種類型：網站、iOS 應用程式和 Android 應用程式。",
    descriptionLink:
      "https://support.google.com/analytics/answer/9355659?hl=zh-Hant",
  },
  {
    key: "3",
    name: { tw: "追蹤網域數量", en: "Domains" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkHostName",
    value: [],
    description: "從您的網站流向 Google Analytics 4 的資料流數量。",
    descriptionLink:
      "https://support.google.com/analytics/answer/10071811?hl=zh-Hant",
  },
  {
    key: "4",
    name: { tw: "是否有跨網域追蹤", en: "Cross Domain Measurement" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkCrossDomain",
    value: false,
    description:
      "讓 Google Analytics 4 將多個相關網站上的工作階段計為同一個工作階段，避免產生不必要的工作階段與來源媒介。",
    descriptionLink:
      "https://support.google.com/analytics/answer/10071811?hl=zh-Hant",
  },
  {
    key: "5",
    name: { tw: "產業類別設定", en: "Industry Category" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkIndustryCategory",
    value: "",
    description: "與資源收集到的數據性質最吻合的產業類別。",
    descriptionLink:
      "https://support.google.com/analytics/answer/1033876?hl=zh-Hant#zippy=%2C%E6%9C%AC%E6%96%87%E5%85%A7%E5%AE%B9",
  },
  {
    key: "6",
    name: { tw: "時區設定", en: "Time Zone" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkTimeZone",
    value: "",
    description: "在報表中顯示的時區，對資料收集方式沒有影響。",
    descriptionLink:
      "https://support.google.com/analytics/answer/1009694?hl=zh-Hant#zippy=%2C%E6%8C%89%E4%B8%80%E4%B8%8B%E4%BB%A5%E5%B1%95%E9%96%8B%E4%B8%A6%E9%80%B2%E4%B8%80%E6%AD%A5%E7%9E%AD%E8%A7%A3%E5%A0%B1%E8%A1%A8%E6%99%82%E5%8D%80%E8%A8%AD%E5%AE%9A%E9%81%8B%E4%BD%9C%E7%9A%84%E6%96%B9%E5%BC%8F",
  },
  {
    key: "7",
    name: { tw: "貨幣設定", en: "Currency" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkCurrencyCode",
    value: "",
    description:
      "在報表中顯示收益、稅金、運費、與總價值等營利指標時所使用的貨幣。",
    descriptionLink:
      "https://support.google.com/analytics/answer/9796179?hl=zh-Hant#specifying-currencies&zippy=%2C%E6%9C%AC%E6%96%87%E5%85%A7%E5%AE%B9",
  },
  {
    key: "8",
    name: { tw: "啟用 Google 信號", en: "Google Signals" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkGoogleSignalsSettings",
    value: "",
    description:
      "將來自網站和應用程式的工作階段資料與已登入 Google 帳戶並啟用廣告個人化的使用者建立關聯，方便後續追蹤跨平台行為、收集客層和興趣的其他相關資訊以及投放 Google 再行銷廣告。",
    descriptionLink:
      "https://support.google.com/analytics/answer/9445345?hl=zh-Hant#zippy=%2C%E6%9C%AC%E6%96%87%E5%85%A7%E5%AE%B9",
  },
  {
    key: "9",
    name: { tw: "資料保存期限", en: "Data Retention" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkDataRetentionSettings",
    value: "",
    description: "設定使用者和事件層級資料的保存期限，請盡量保存 14 個月。",
    descriptionLink:
      "https://support.google.com/analytics/answer/7667196?hl=zh-Hant",
  },
  {
    key: "10",
    name: { tw: "定義使用者屬性", en: "User Properties" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkUserProperties",
    value: [],
    description:
      "預先在在使用者身上貼上標籤進行分類，決定他們的靜態或緩變屬性，可以協助您去辨識與篩選使用者。",
    descriptionLink:
      "https://support.google.com/analytics/answer/10075209?hl=zh-Hant&ref_topic=11151952",
  },
  {
    key: "11",
    name: { tw: "串接 Google Ads", en: "Google Ads Linking" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkGoogleAdsLinks",
    value: [],
    description:
      "從使用者如何與您的行銷活動互動 (例如點擊廣告)，到最後如何在網站或應用程式上完成您設定的目標 (例如購買商品、觀看內容)，完整查看客戶週期。",
    descriptionLink:
      "https://support.google.com/analytics/answer/9379420#zippy=%2C%E6%9C%AC%E6%96%87%E5%85%A7%E5%AE%B9",
  },
  {
    key: "12",
    name: { tw: "客戶有效互動率", en: "Engagement Rate" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkInteractionRate",
    value: 0,
    description:
      "應用程式於前景運作或網頁成為焦點的時間長度，有助您瞭解使用者花費多少時間與內容互動，一般建議不要低於 80%。",
    descriptionLink:
      "https://support.google.com/analytics/answer/11109416?hl=zh-Hant",
  },
  {
    key: "13",
    name: { tw: "非預設轉換事件", en: "Conversion Events (Non-default)" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkConversionEvents",
    value: [],
    description:
      "使用者與您網站和/或應用程式互動時能夠帶來業務成效或達成目標的事件數量，這裡顯示的是非預設的轉換事件。",
    descriptionLink:
      "https://support.google.com/analytics/answer/9267568?hl=zh-Hant",
  },
  {
    key: "14",
    name: { tw: "定義目標對象", en: "Audiences" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkAudience",
    value: [],
    description:
      "根據自家業務需求來區隔使用者群。您可以根據維度、指標和事件來區隔。",
    descriptionLink:
      "https://support.google.com/analytics/answer/9267572?hl=zh-Hant#zippy=%2C%E6%9C%AC%E6%96%87%E5%85%A7%E5%AE%B9",
  },
  {
    key: "15",
    name: { tw: "廣告預測功能", en: "Predictive Audiences" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkPredicted",
    value: [],
    description:
      "根據特定預測指標，符合至少一項條件的目標對象。舉例來說，您可根據「未來 7 天內的潛在購買者」建立目標對象，將未來 7 天內可能購物的使用者納入指定範圍，而這些目標對象可以匯入至 Google Ads 後台投放再行銷廣告。",
    descriptionLink:
      "https://support.google.com/analytics/answer/9805833?hl=zh-Hant",
  },
  {
    key: "16",
    name: { tw: "電子商務代碼", en: "E-Commerce" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkEccomerce",
    value: [],
    description:
      "在網站或行動應用程式中加入這些事件，營利報表中就會填入資料，有助您評估與優化網站或應用程式的銷售業績。",
    descriptionLink:
      "https://support.google.com/analytics/answer/9267735?hl=zh-Hant#",
  },
  {
    key: "17",
    name: { tw: "工作階段比例", en: "Sessions" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkMedium",
    value: [],
    description:
      "推薦使用者工作階段的網站和管道，有助您評估目前廣告帶來的流量比例。",
    descriptionLink:
      "https://support.google.com/analytics/answer/9143382?hl=zh-Hant",
  },
  {
    key: "18",
    name: { tw: "User-ID 設定", en: "User-ID Set Up" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkUserId",
    value: [],
    description:
      "指客戶產生的身份編號，例如：會員編號，用來區分使用者並將使用者事件統整在報表和探索中。",
    descriptionLink:
      "https://developers.google.com/analytics/devguides/collection/ga4/user-id?technology=gtagjs",
  },
  {
    key: "19",
    name: { tw: "Measurement Protocol", en: "Measurement Protocol" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkDataStreamsMeasurementProtocol",
    value: [],
    description:
      "可以讓您從資訊站、銷售系統站點，或是網站或行動應用程式以外的任何地點將資料傳送給 Google Analytics 的後台。",
    descriptionLink:
      "https://support.google.com/analytics/answer/9900444?hl=zh-Hant",
  },
  {
    key: "20",
    name: { tw: "Google Ads 轉換成效", en: "Conversion From Google Ads" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkGoogleCPCTrans",
    value: [],
    description:
      "查看 Google Ads 為您的網站或是 APP 帶來了多少的轉換成效，其所帶來流量的「來源/媒介」維度值是「google/cpc」。",
    descriptionLink: "",
  },
  {
    key: "21",
    name: { tw: "是否有異常流量", en: "Unassigned Channel" },
    result: "分析中",
    status: "尚未檢核",
    type: "checkUnassigned",
    value: 0,
    description:
      "查看網站是否有異常流量導流的使用者，代表系統無法判斷這些使用者是如何造訪。",
    descriptionLink:
      "https://support.google.com/analytics/answer/9756891?hl=zh-Hant",
  },
];
