import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link, NavLink } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { SettingOutlined, ProfileOutlined, LineChartOutlined } from '@ant-design/icons'

import './NavLeft.scss'
import logo from '../../images/logo.jpg'

const { Sider } = Layout
const { SubMenu } = Menu

const NavLeft = () => {
  const [collapsed] = useState(true)

  return (
    <Sider
      // trigger={null}
      // collapsible
      // collapsed={collapsed}
      className="nav-left"

      breakpoint="sm"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <div className="logoItem">
        <Link to="/overview">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </Link>
      </div>
      <div className="notice">
        <Link to="/overview">免費體驗</Link>
      </div>
      {/* <Menu theme="dark" mode="inline">
        <Menu.Item key="1" className="logoItem">
          <Link to="/overview">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
          </Link>
        </Menu.Item>
        <Menu.Item key="2">
          <div className="notice">免費體驗</div>
        </Menu.Item>
      </Menu> */}
    </Sider>
  )
}

export default NavLeft
