export const isLocalEnv = () => {
  return location.hostname == "localhost" || location.hostname == "127.0.0.1";
};

// BigQuery 儲存成本計算器
// https://www.bq4ga.com/bigquery-cost-calculator-for-ga4/
// https://www.analyticstraps.com/bigquery-storage-cost-calculator/
// https://cloud.google.com/products/calculator?hl=zh_tw
// 1 GB 相當於大約 60 萬個 Google Analytics (分析) 事件
// https://support.google.com/analytics/answer/9358801?hl=zh-Hant
// https://cloud.google.com/bigquery/pricing?hl=zh-tw#storage
export const calculateBigQueryStorageAndCost = (
  dailyEventCount, // 每日事件數（單位：萬）
  months
) => {
  const unitMultiplier = 10000; // 每日事件數的單位轉換（萬）
  const eventsPerGB = 600000; // 每 GB 對應的事件數量
  const gbToGibConversionFactor = 0.93; // GB 轉換為 GiB 的係數
  const eventsPerGiB = eventsPerGB / gbToGibConversionFactor; // 每 GiB 對應的事件數量
  const costPerGiBPerMonthUSDActive = 0.02; // 每 GiB 每月的費用（美金）- 活躍存儲
  const costPerGiBPerMonthUSDLongTerm = 0.01; // 每 GiB 每月的費用（美金）- 長期存儲
  const exchangeRate = 32; // 美金兌換台幣匯率
  const freeQuotaGiB = 10; // 每月 10 GiB 的免費額度
  const freeQuotaEvents = freeQuotaGiB * eventsPerGiB; // 每月免費額度轉換成事件數

  let totalStorageGB = 0;
  let totalCost = 0;
  let remainingFreeQuotaEvents = freeQuotaEvents * months; // 總共可用的免費額度（事件數）
  let usedFreeStorageGiB = 0; // 用於計算每月的免費額度

  // 存儲每個月的存儲量，用於計算長期存儲費用
  const monthlyStorage = [];
  const activeStorageHistory = [];
  const longTermStorageHistory = [];

  const monthlyDetails = [];

  for (let month = 1; month <= months; month++) {
    // console.log("===== start ======");
    const monthlyEventCount = dailyEventCount * unitMultiplier * 30; // 假設每月有30天
    const monthlyStorageGB = monthlyEventCount / eventsPerGB;
    const monthlyStorageGiB =
      Math.round(monthlyStorageGB * gbToGibConversionFactor * 1000) / 1000; // 將當月存儲從 GB 轉換為 GiB 並四捨五入到小數點第三位
    totalStorageGB += monthlyStorageGB;

    // 將當月存儲量加入記錄
    monthlyStorage.push(monthlyStorageGiB);

    // 計算當月的活躍存儲費用和長期存儲費用
    let activeStorageGiB = 0;
    let longTermStorageGiB = 0;

    // 計算活躍存儲部分
    activeStorageGiB = monthlyStorage.reduce((acc, val) => acc + val, 0);

    // 計算使用的免費額度
    if (activeStorageGiB > freeQuotaGiB) {
      usedFreeStorageGiB = freeQuotaGiB;
      activeStorageGiB -= freeQuotaGiB;
    } else {
      usedFreeStorageGiB = activeStorageGiB;
      activeStorageGiB = 0;
    }

    const billableActiveStorageGiB = Math.round(activeStorageGiB * 1000) / 1000; // 四捨五入到小數點第三位

    // 處理長期存儲部分
    longTermStorageGiB = longTermStorageHistory.reduce(
      (acc, val) => acc + val,
      0
    );

    // 更新歷史活躍存儲記錄，先判斷 billableActiveStorageGiB > 0 再 push 到 activeStorageHistory
    if (billableActiveStorageGiB > 0) {
      if (billableActiveStorageGiB < monthlyStorageGiB) {
        activeStorageHistory.push(billableActiveStorageGiB);
      } else {
        activeStorageHistory.push(monthlyStorageGiB);
      }
    }

    // 如果 activeStorageHistory 中的月份超過三個，將最早的活躍存儲量移到長期存儲
    if (activeStorageHistory.length > 3) {
      longTermStorageHistory.push(activeStorageHistory.shift());
    }

    // console.log("activeStorageHistory = ", activeStorageHistory);
    // console.log("longTermStorageHistory = ", longTermStorageHistory);

    const totalBillableActiveStorageGiB =
      Math.round(
        activeStorageHistory.reduce((acc, val) => acc + val, 0) * 1000
      ) / 1000; // 四捨五入到小數點第三位
    const totalBillableLongTermStorageGiB =
      Math.round(
        longTermStorageHistory.reduce((acc, val) => acc + val, 0) * 1000
      ) / 1000; // 四捨五入到小數點第三位

    // 計算當月費用
    const monthlyCostUSDActive =
      totalBillableActiveStorageGiB * costPerGiBPerMonthUSDActive;
    const monthlyCostUSDLongTerm =
      totalBillableLongTermStorageGiB * costPerGiBPerMonthUSDLongTerm;
    const monthlyCostTWD =
      (monthlyCostUSDActive + monthlyCostUSDLongTerm) * exchangeRate;

    totalCost += monthlyCostTWD;

    // 更新剩餘的免費額度事件數
    const totalEventsThisMonth = usedFreeStorageGiB * eventsPerGiB;
    remainingFreeQuotaEvents -= Math.min(freeQuotaEvents, totalEventsThisMonth);

    // 記錄每月的詳細信息
    monthlyDetails.push({
      month,
      freeStorageGiB: usedFreeStorageGiB,
      activeStorageGiB: totalBillableActiveStorageGiB,
      longTermStorageGiB: totalBillableLongTermStorageGiB,
      monthlyCost: Math.round(monthlyCostTWD), // 整數四捨五入
    });

    console.log(
      `Month ${month}: Free Storage: ${usedFreeStorageGiB} GiB, Active Storage: ${totalBillableActiveStorageGiB} GiB, Long-term Storage: ${totalBillableLongTermStorageGiB} GiB, Monthly Cost: ${Math.round(monthlyCostTWD)} TWD`
    );
    // console.log("===== end ======");
  }

  // 四捨五入處理
  const totalStorageGiB =
    Math.round(totalStorageGB * gbToGibConversionFactor * 1000) / 1000; // 將總存儲從 GB 轉換為 GiB 並四捨五入到小數點第三位
  const remainingFreeQuotaGiB =
    Math.round(Math.max(freeQuotaGiB - usedFreeStorageGiB, 0) * 1000) / 1000; // 計算剩餘的免費額度 GiB 並四捨五入到小數點第三位

  totalCost = Math.round(totalCost); // 整數四捨五入

  return {
    totalStorageGB,
    totalStorageGiB,
    totalCost,
    remainingFreeQuotaEvents,
    remainingFreeQuotaGiB,
    monthlyDetails,
  };
};

export const bigQueryEventGB = (pX, pN) => {
  // https://support.google.com/analytics/answer/9358801?hl=zh-Hant
  // https://cloud.google.com/bigquery/pricing?hl=zh-tw#storage
  let X = (pX * 30) / 60; // 一個月的儲存 GB
  let Y = 10;
  let N = pN;
  let sumGB = ((N * (N + 1)) / 2) * X;
  // console.log("🚀 ~ bigQueryEventGB ~ sumGB:", sumGB);
  // let sumPrice = sumGB * 0.02 * 31;
  // return Math.floor(sum);
  return sumGB > 0 ? sumGB.toFixed(3) : 0;
};

export const bigQueryEventPrice = (pX, pN) => {
  // https://support.google.com/analytics/answer/9358801?hl=zh-Hant
  // https://cloud.google.com/bigquery/pricing?hl=zh-tw#storage
  let X = (pX * 30) / 60;
  let Y = 10;
  let N = pN;
  let sumGB = (N * (X * (N + 1) - 2 * Y)) / 2;
  let sumPrice = sumGB * 0.02 * 31;
  // return Math.floor(sum);
  return sumPrice > 0 ? Math.round(sumPrice) : 0;
};

const sendGAEvent = (pEventName, pParameterObj) => {
  // var dataLayer = window.dataLayer || [];
  var pParameterObj = pParameterObj || {};

  // var dataLayerObj = Object.assign(
  //   {
  //     event: "cusevent-ga4check-dev",
  //     eventName: pEventName,
  //   },
  //   pParameterObj
  // );

  // 本地測試不用送 api
  if (!isLocalEnv() && pEventName) {
    gtag("event", pEventName, pParameterObj);
  } else {
    console.log("eventName = " + pEventName, pParameterObj);
    // dataLayer.push(dataLayerObj);
    // dataLayer.push({
    //   td_parameter: undefined,
    // });

    // // 重置參數
    // Object.keys(pParameterObj).forEach((key) => {
    //   pParameterObj[key] = undefined;
    // });
    // dataLayer.push(pParameterObj);
  }
};

export default {
  sendGAEvent,
  isLocalEnv,
  bigQueryEventGB,
  bigQueryEventPrice,
  calculateBigQueryStorageAndCost,
};
