import React from "react";
import { HashRouter, BrowserRouter, Routes, Route } from "react-router-dom";
// import Login from "./pages/Login/Login";
// import Overview from "./pages/Overview/Overview";
// import Management from "./pages/attribution-pages/Management/Management";
import AdminLayout from "./components/AdminLayout/AdminLayout";
import CheckAssistant from "./pages/CheckAssistant/CheckAssistant";
import BigqueryPricingCalculator from "./pages/bigquery-pricing-calculator/bigquery-pricing-calculator";
// import Choose from "./pages/choose/Choose";
// import Result from "./pages/result/Result";
// import DevIndex from "./pages/index/Index";
// import ReportCart from "./pages/reportCart/reportCart";
// import Report from "./pages/report/report";
// import User from "./pages/user/user";
import { GoogleStoreProvider } from "./pages/CheckAssistant/GoogleStore";

import ScrollToTop from "./helpers/ScrollToTop";
// import PrivateRoute from "./PrivateRoute";

const Router = () => (
  <GoogleStoreProvider>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<CheckAssistant />} />
        {/* Big Query Pricing Calculator */}
        <Route
          path="/bigquery-pricing-calculator"
          // path="/"
          // element={<CheckAssistant />}
          element={<BigqueryPricingCalculator />}
        />
        {/* <Route path="/dev" element={<DevIndex />} /> */}

        {/* <Route path="/admin/" element={<AdminLayout />}>
          <Route path="choose-property" element={<Choose />} />
          <Route path="analysis-results" element={<Result />} />
          <Route
            path="sales-overview"
            element={<ReportCart rolePath="/admin" />}
          />
          <Route path="sales-report" element={<Report rolePath="/admin" />} />
          <Route path="setting" element={<User rolePath="/admin" />} />
          <Route path="*" element={<Choose />} />
        </Route> */}

        {/* <Route path="/management" element={<Management />} /> */}

        {/* ga4check */}
        {/* <PrivateRoute path="/choose">
          <Choose />
        </PrivateRoute>
        <PrivateRoute path="/result">
          <Result />
        </PrivateRoute> */}
        {/* <Route path="*" element={<CheckAssistant />} /> */}

        {/* 功勞歸屬頁面 */}
        {/* <Route path="/login" component={Login} />

        <PrivateRoute path="/overview">
          <Overview />
        </PrivateRoute>

        <PrivateRoute path="/management">
          <Management />
        </PrivateRoute> */}
      </Routes>
    </BrowserRouter>
  </GoogleStoreProvider>
);

export default Router;
