import React, { useState, useEffect } from "react"

const Device = () => {
  const [device, setDevice] = useState("")

  const handleRWD = () => {
    // console.log("window.innerWidth = ", window.innerWidth)
    if (window.innerWidth > 768) setDevice("PC")
    else if (window.innerWidth > 575) setDevice("tablet")
    else setDevice("mobile")
  }

  useEffect(() => {
    // console.log("resize")
    window.addEventListener("resize", handleRWD)
    return () => {
      // console.log(" remove resize")
      window.removeEventListener("resize", handleRWD)
    }
  }, [])

  return device
}

export default Device
