import React, { useEffect, useState, useCallback } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
import { Layout, Menu, Breadcrumb } from "antd";
import NavLeft from "../NavLeft/NavLeft";
import NavTop from "../NavTop/NavTop";

import logo from "../../images/logo.jpg";
import "./AdminLayout.scss";
import docCookies from "../../utils/docCookies";

import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  LaptopOutlined,
  NotificationOutlined,
  PieChartOutlined,
  DesktopOutlined,
  TeamOutlined,
  FileOutlined,
  AreaChartOutlined,
  SettingOutlined,
  ProfileOutlined,
  FileTextOutlined,
  BarChartOutlined,
} from "@ant-design/icons";
const { Header, Content, Footer, Sider } = Layout;

// import Header from "../../components/Header/Header";
import imgLogo from "../../images/checkAssistant/logo.png";

const { SubMenu } = Menu;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));
const items2 = [
  getItem("Option 1", "1", <PieChartOutlined />),
  getItem("Option 2", "2", <DesktopOutlined />),
  getItem("User", "sub1", <UserOutlined />, [
    getItem("Tom", "3"),
    getItem("Bill", "4"),
    getItem("Alex", "5"),
  ]),
  getItem("Team", "sub2", <TeamOutlined />, [
    getItem("Team 1", "6"),
    getItem("Team 2", "8"),
  ]),
  getItem("Files", "9", <FileOutlined />),
];

const rolePath = "/admin";

const AdminLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(1);

  console.log("AdminLayout selectedKeys = ", selectedKeys);
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Header
        className="header"
        style={{
          position: "fixed",
          left: "0px",
          top: "0px",
          width: "100%",
          zIndex: "3",
        }}
      >
        <div className="logo">
          <Link to="/dev">
            <img src={imgLogo} />
          </Link>
        </div>
        {/* <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={["2"]}
          items={items1}
        /> */}
      </Header>
      <Layout
        style={{
          padding: "64px 0 0 200px",
        }}
      >
        <Sider
          breakpoint="md"
          // collapsedWidth="0"
          // onBreakpoint={(broken) => {
          //   console.log("broken = ", broken);
          // }}
          // collapsible
          // collapsed={collapsed}
          // onCollapse={(collapsed, type) => {
          //   console.log("collapsed = ", collapsed);
          //   console.log("type = ", type);
          //   setCollapsed(collapsed);
          // }}
          style={{
            overflow: "auto",
            height: "calc(100vh - 64px)",
            position: "fixed",
            left: "0px",
            top: "64px",
            bottom: "0px",
            background: "#FFF",
          }}
        >
          {/* <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{
              height: "100%",
              borderRight: 0,
            }}
            items={items2}
          /> */}
          <Menu
            // theme="dark"
            // defaultSelectedKeys={["2"]}
            selectedKeys={[selectedKeys]}
            mode="inline"
          >
            {/* <Menu.Item key="1" className="logoItem" style={{ height: 54 }}>
              <Link to={`${rolePath}/reportCart`}>
                <div className="logo">
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: "30px", marginRight: "10px" }}
                  />
                  Turing Digital
                </div>
              </Link>
            </Menu.Item> */}
            <Menu.Item key="1">
              <Link to={`${rolePath}/choose-property`}>
                <FileTextOutlined />
                <span className="nav-text">選擇資源</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to={`${rolePath}/analysis-results`}>
                <BarChartOutlined />
                <span className="nav-text">分析結果</span>
              </Link>
            </Menu.Item>
            <SubMenu
              key="sub1"
              title={
                <span>
                  <ProfileOutlined />
                  <span className="nav-text">銷售收益</span>
                </span>
              }
            >
              <Menu.Item key="3">
                <Link to={`${rolePath}/sales-overview`}>
                  <span className="nav-text">收益總覽</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to={`${rolePath}/sales-report`}>
                  <span className="nav-text">收益報表</span>
                </Link>
              </Menu.Item>
            </SubMenu>
            {/* <Menu.Item key="3">
              <Link to={`${rolePath}/setting`}>
                <SettingOutlined />
                <span className="nav-text">推薦產品</span>
              </Link>
            </Menu.Item> */}
            {/* <Menu.Item key="2">
              <Link to={`${rolePath}/overview`}>
                <AreaChartOutlined />
                <span className="nav-text">提領現金</span>
              </Link>
            </Menu.Item> */}
            <Menu.Item key="5">
              <Link to={`${rolePath}/setting`}>
                <UserOutlined />
                <span className="nav-text">帳戶資訊</span>
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout
          style={{
            padding: "24px 24px 0",
          }}
        >
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 360,
            }}
          >
            {/* {children} */}
            <Outlet context={[selectedKeys, setSelectedKeys]} />
          </Content>
          <Footer style={{ textAlign: "center" }}>
            © 2022 Turing Digital. All Rights Reserved
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

// AdminLayout.propTypes = {
//   children: PropTypes.element.isRequired,
// };

export default AdminLayout;
