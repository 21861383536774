import React from "react";
import PropTypes from "prop-types";
import { Menu } from "antd";

import "./NavTop.scss";

import GoogleLoginButton from "../../pages/attribution-pages/Login/GoogleLoginButton";

const { SubMenu } = Menu;

function Header(props) {
  return (
    <div className="header-wrap">
      <Menu
        mode="horizontal"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          height: "52px",
          padding: "3px 0",
          paddingRight: "20px",
        }}
      >
        <Menu.Item
          key={"GoogleLoginButton"}
          style={{
            display: "inline-flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <GoogleLoginButton />
        </Menu.Item>
      </Menu>
    </div>
  );
}

Header.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default Header;
