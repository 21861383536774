import { createStore, compose } from "redux";
import languageReducer from "./language/languageReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* eslint-disable no-underscore-dangle */
const store = createStore(languageReducer, composeEnhancers());

// export type RootState = ReturnType<typeof store.getState>;

export default store;
